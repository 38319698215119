/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-use-before-define */
/* eslint-disable no-console */
import React, { useEffect, useRef } from "react";
import axios from "axios";
import { useSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import config from "../../../config/config";
import { webrtcActions } from "../webrtcSlice";
import { ipAddressSliceActions } from "../../../features/ip-address/ipAddressSlice";

const PEER_CONNECTION_OPTIONS = { optional: [{ DtlsSrtpKeyAgreement: true }] };
const uiServerOrVstEndpointString = config.useVstApiDirectly
  ? `${config.vstEndpoint}`
  : `${config.uiServerEndpoint}`;
const vmsOrApiString = config.useVstApiDirectly ? "/api" : "/vms";
const MAX_RETRIES = 3;

function WebrtcDataChannel() {
  const earlyCandidates = useRef([]);
  const pc = useRef();
  const peerId = useRef();
  const restartCount = useRef(0);
  const dispatch = useDispatch();
  // eslint-disable-next-line no-unused-vars
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    startWebrtcConnection();
    return () => {
      stopWebrtcConnection();
    };
  }, []);

  const addIceCandidate = (candidate) => {
    const jsonData = {
      peerid: pc.current.peerId.toString(),
      candidate,
    };
    console.debug("DATA CHANNEL: calling vms/addIceCandidate with ", jsonData);
    const promise = axios(
      `${uiServerOrVstEndpointString}${vmsOrApiString}/addIceCandidate`,
      {
        method: "post",
        data: jsonData,
      }
    );
    promise.then((response) => {
      console.log("DATA CHANNEL: added ice candidate ?", response.data);
    });
    promise.catch((e) => {
      console.error("DATA CHANNEL: addIceCandidate error", e);
    });
  };

  const onReceiveCandidate = (response) => {
    const candidates = response.data;
    console.debug(
      `DATA CHANNEL: Received candidates from VMS: ${JSON.stringify(
        candidates
      )}`
    );
    if (candidates) {
      console.debug(
        "DATA CHANNEL: Creating RTCIceCandidate from each received candidate.."
      );
      for (let i = 0; i < candidates.length; i += 1) {
        const candidate = new RTCIceCandidate(candidates[i]);

        console.debug(
          `DATA CHANNEL: Adding ICE candidate - ${i} :${JSON.stringify(
            candidate
          )}`
        );
        pc.current.addIceCandidate(
          candidate,
          () => {
            console.debug(`DATA CHANNEL: addIceCandidate OK - ${i}`);
          },
          (error) => {
            console.debug(`DATA CHANNEL: addIceCandidate error - ${i}`, error);
          }
        );
      }
    }
  };

  const getIceCandidate = () => {
    console.debug("DATA CHANNEL: calling api/getIceCandidate");
    axios
      .get(
        `${uiServerOrVstEndpointString}${vmsOrApiString}/getIceCandidate?peerid=${pc.current.peerId}`
      )
      .then(onReceiveCandidate)
      .catch((e) => {
        console.error("DATA CHANNEL: getIceCandidate error", e);
      });
  };

  const setRemoteDescription = (sessionDescriptionAnswer) => {
    pc.current
      .setRemoteDescription(new RTCSessionDescription(sessionDescriptionAnswer))
      .then(() => {
        earlyCandidates.current.forEach(addIceCandidate);
        getIceCandidate();
      })
      .catch((e) => {
        console.error("DATA CHANNEL: onReceiveCall: ", e);
      });
  };

  const sendSessionDescriptionToVst = (sessionDescription) => {
    const sessionDescriptionPayload = {
      peerid: pc.current.peerId.toString(),
      is_dataChannel: true,
      options: {
        rtptransport: "udp",
        timeout: 60,
      },
      sessionDescription,
    };
    console.debug(
      "DATA CHANNEL: Payload of stream start: ",
      sessionDescriptionPayload
    );

    const promise = axios(
      `${uiServerOrVstEndpointString}${vmsOrApiString}/stream/start`,
      {
        method: "post",
        data: sessionDescriptionPayload,
      }
    );
    promise.then((response) => {
      const sessionDescriptionAnswer = response.data;
      console.debug(
        "DATA CHANNEL: Received response of stream start: ",
        sessionDescriptionAnswer
      );
      console.debug(
        "DATA CHANNEL: Signaling state is ",
        pc.current.signalingState
      );
      if (pc.current.signalingState === "have-local-offer") {
        console.debug("DATA CHANNEL: OK signalingState");
      } else {
        console.debug("DATA CHANNEL: unexpected signalingState");
      }
      setRemoteDescription(sessionDescriptionAnswer);
    });
    promise.catch((e) => {
      console.error("DATA CHANNEL: wertc error: ", e);
      // eslint-disable-next-line no-use-before-define
    });
  };

  const createOffer = () => {
    pc.current
      .createOffer()
      .then((sessionDescription) => {
        console.debug("DATA CHANNEL: session Description", sessionDescription);
        pc.current.setLocalDescription(sessionDescription);
        return sessionDescription;
      })
      .then((sessionDescription) => {
        sendSessionDescriptionToVst(sessionDescription);
      })
      .catch((error) => {
        console.error("DATA CHANNEL: Failed to create offer", error);
      });
  };

  const onConnectionStateChange = () => {
    // FireFox does not support onConnectionStateChange event
    console.info(
      "DATA CHANNEL: connection state change: ",
      pc.current.connectionState
    );
    if (pc.current.connectionState === "disconnected") {
      console.debug("DATA CHANNEL: Lost peer connection...");
    }
  };

  const onSignalingStateChange = () => {
    console.info(
      "DATA CHANNEL: signaling state change: ",
      pc.current.signalingState
    );
  };

  const onIceGatheringStateChange = () => {
    console.info(
      "DATA CHANNEL: gathering state change: ",
      pc.current.iceGatheringState
    );
  };

  const onIceCandidateError = (e) => {
    console.debug("DATA CHANNEL: onIceCandidateError: ", e);
    if (e.errorCode !== 701) {
      console.error(
        `${e.errorText} error code ${e.errorCode} and url ${e.url}`,
        "onIceCandidateError"
      );
    }
    if (e.errorCode === 701) {
      console.debug(
        "error code is 701 that means DNS failed for ipv6, harmless error"
      );
    }
  };

  const onIceConnectionStateChange = () => {
    console.info(
      "ice connection state change: ",
      pc.current.iceConnectionState
    );
    if (pc.current && pc.current.iceConnectionState === "new") {
      getIceCandidate();
    }
    if (pc.current.iceConnectionState === "failed") {
      console.log(
        "DATA CHANNEL ice connection state: ",
        pc.current.iceConnectionState
      );
    }
    if (pc.current.iceConnectionState === "disconnected") {
      console.log(
        "DATA CHANNEL ice connection state: ",
        pc.current.iceConnectionState
      );
    }
    if (pc.current.iceConnectionState === "connected") {
      console.log(
        "DATA CHANNEL ice connection state: ",
        pc.current.iceConnectionState
      );
      restartCount.current = 0;
    }
  };

  const onIceCandidate = (event) => {
    if (!event.candidate) {
      console.debug(
        "received null candidate, that means its the last candidate"
      );
      return;
    }
    if (event.candidate) {
      if (event.candidate.candidate.length === 0) {
        console.debug(
          "Recevived empty string for candidate - client is firefox"
        );
        return;
      }
      console.debug(
        "received candidate inside onIceCandidate callback: ",
        event.candidate.candidate
      );
      // If a srflx candidate was found, notify that the STUN server works!
      if (event.candidate.type === "srflx") {
        console.debug(
          "DATA CHANNEL: The STUN server is reachable for this candidate!"
        );
        console.debug(`Public IP Address is: ${event.candidate.address}`);
        dispatch(
          ipAddressSliceActions.updateIPAddress(
            event.candidate.address
          )
        );
      }
      // If a relay candidate was found, notify that the TURN server works!
      if (event.candidate.type === "relay") {
        console.debug(
          "DATA CHANNEL: The TURN server is reachable for this candidate!"
        );
      }
      if (pc.current && pc.current.currentRemoteDescription) {
        addIceCandidate(event.candidate);
      } else {
        earlyCandidates.current.push(event.candidate);
      }
    }
  };

  const createRTCPeerConnection = (iceServers) => {
    console.debug("DATA CHANNEL: ICE Servers: ", iceServers);
    const dataChannelOptions = {
      ordered: false, // do not guarantee order
      maxPacketLifeTime: 3000, // in milliseconds
    };
    try {
      const peerConnection = new RTCPeerConnection(
        { iceServers },
        PEER_CONNECTION_OPTIONS
      );
      peerConnection.peerId = peerId.current;
      peerConnection.onicecandidate = onIceCandidate;
      peerConnection.oniceconnectionstatechange = onIceConnectionStateChange;
      peerConnection.onicecandidateerror = onIceCandidateError;
      peerConnection.onicegatheringstatechange = onIceGatheringStateChange;
      peerConnection.onsignalingstatechange = onSignalingStateChange;
      peerConnection.onconnectionstatechange = onConnectionStateChange;

      const dataChannel = peerConnection.createDataChannel(
        "tokkio-ui-data-channel",
        dataChannelOptions
      );

      dataChannel.onerror = (error) => {
        console.log("Data Channel Error:", error);
        enqueueSnackbar("Data channel disconnected", { variant: "error" });
      };

      dataChannel.onmessage = (event) => {
        // FORMAT: {"message_catogory":"network_qos","message_type":"alert","network_bandwidth_state":"low"}
        try {
          const alertMessage = JSON.parse(event.data);
          if (
            alertMessage.network_bandwidth_state === "low" ||
            alertMessage.network_bandwidth_state === "high"
          ) {
            dispatch(
              webrtcActions.setWebrtcNetworkQuality(
                alertMessage.network_bandwidth_state
              )
            );
          }
        } catch (error) {
          console.log("failed to parse data channel message");
        }
      };

      dataChannel.onclose = () => {
        console.log("The Data Channel is Closed");
        enqueueSnackbar("Data channel closed", { variant: "error" });
      };

      pc.current = peerConnection;
    } catch (error) {
      console.error(`Failed to create RTC peer connection.`, error);
    }
    createOffer();
  };

  const startWebrtcConnection = () => {
    peerId.current = Math.random();
    axios
      .get(
        `${uiServerOrVstEndpointString}${vmsOrApiString}/getIceServers?peerId=${peerId.current}`
      )
      .then((response) => {
        console.log(response.data);
        createRTCPeerConnection(response.data.iceServers);
      })
      .catch((rejectedValueOrSerializedError) => {
        console.error(rejectedValueOrSerializedError);
        enqueueSnackbar("Failed to get ICE servers for data channel", {
          variant: "error",
        });
        restartWebRtcConnection();
      });
  };

  const stopWebrtcConnection = () => {
    if (pc.current != null) {
      const jsonData = {
        peerid: pc.current.peerId.toString(),
      };
      pc.current.close();
      pc.current = null;
      peerId.current = null;
      earlyCandidates.current = [];
      console.debug("DATA CHANNEL: calling api/stream/stop with ", jsonData);
      const promise = axios(
        `${uiServerOrVstEndpointString}${vmsOrApiString}/stream/stop`,
        {
          method: "post",
          data: jsonData,
        }
      );
      promise.then((response) => {
        console.log("DATA CHANNEL: stream stop ?", response.data);
      });
      promise.catch((e) => {
        console.error("DATA CHANNEL: stream stop error", e);
      });
    }
  };

  const restartWebRtcConnection = () => {
    if (restartCount.current < MAX_RETRIES) {
      // add delay to avoid page hang
      setTimeout(() => {
        stopWebrtcConnection();
        startWebrtcConnection();
        restartCount.current += 1;
      }, 2000);
      console.debug("DATA CHANNEL: RESTARTING WEBRTC CONNECTION !!!");
    } else {
      console.debug(
        "DATA CHANNEL: WEBRTC MAX RETRY LIMIT REACHED, RESTART PAGE !!!"
      );
      enqueueSnackbar(
        "Unable to connect to data channel, please refresh page",
        {
          variant: "error",
        }
      );
    }
  };

  return <></>;
}

export default WebrtcDataChannel;
