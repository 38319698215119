/* eslint-disable no-param-reassign */
/* eslint-disable no-use-before-define */
/* eslint-disable no-console */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef } from "react";
import axios from "axios";
import MicIcon from "@mui/icons-material/Mic";
import MicOffIcon from "@mui/icons-material/MicOff";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import VolumeUpOutlinedIcon from "@mui/icons-material/VolumeUpOutlined";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ClosedCaptionIcon from '@mui/icons-material/ClosedCaption';
import ClosedCaptionDisabledIcon from '@mui/icons-material/ClosedCaptionDisabled';
import { useSnackbar } from "notistack";
import { useSelector, useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import config, { WEBRTC_STATUS } from "../../../config/config";
import { webrtcActions } from "../webrtcSlice";
import { webSocketActions } from "../../websocket/websocketSlice";
import WebrtcDataChannel from "../webrtc-data-channel/webrtcDataChannel";
import { ccStatusActions } from "../../../features/captions/CaptionsToggleSlice";
import { ipAddressSliceActions } from "../../../features/ip-address/ipAddressSlice";
import { micEnabledSliceActions } from "../../../features/top-bar/micEnabledSlice";
import StartConversation from "../../../features/start-conversation/StartConversation";

const PEER_CONNECTION_OPTIONS = { optional: [{ DtlsSrtpKeyAgreement: true }] };
const OFFER_OPTIONS = { offerToReceiveAudio: true, offerToReceiveVideo: true };
const uiServerOrVstEndpointString = config.useVstApiDirectly ? `${config.vstEndpoint}` : `${config.uiServerEndpoint}`;
const vmsOrApiString = config.useVstApiDirectly ? "/api" : "/vms";

let stream;

function WebrtcSender() {
  const earlyCandidates = useRef([]);
  const pc = useRef();
  const peerId = useRef();
  const audioBitrate = useRef();
  const degradationPreference = useRef("resolution");
  const maxFps = useRef();
  const connectionId = useRef();
  const ovState = useSelector((state) => state.ovState.ov);
  const ccState = useSelector((state) => state.ccState.status);
  const dispatch = useDispatch();
  const webrtcStatus = useSelector(
    (state) => state.webrtcStatus.webrtcSenderStatus
  );
  // const [micEnabled, setMicEnabled] = useState(true);
  const micEnabledState = useSelector((state) => state.micEnabledState.micEnabled)
  const micDisableButton = useSelector((state) => state.micEnabledState.disableMicButton)
  const webrtcNetworkQuality = useSelector(
    (state) => state.webrtcStatus.webrtcNetworkQuality
  );

  // eslint-disable-next-line no-unused-vars
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    handleRecording();
    return () => {
      stopWebrtcConnection();
    };
  }, []);

  useEffect(() => {
    if (webrtcStatus === WEBRTC_STATUS.ERROR) {
      enqueueSnackbar(
        "Failed to capture and send web cam stream, please refresh page",
        { variant: "error" }
      );
      dispatch(webSocketActions.setStreamId(""));
    } else if (webrtcStatus === WEBRTC_STATUS.CONNECTED) {
      dispatch(webSocketActions.setStreamId(connectionId.current));
    }
  }, [webrtcStatus]);

  useEffect(() => {
    if(stream && stream.getAudioTracks()[0]) {
    if (micEnabledState === true) {
      dispatch(micEnabledSliceActions.enableMic());
      stream.getAudioTracks()[0].enabled = true;
    } else if (micEnabledState === false) {
      dispatch(micEnabledSliceActions.disableMic())
      stream.getAudioTracks()[0].enabled = false;
    }
  }
  }, [micEnabledState]);

  const handleRecording = async () => {
    dispatch(webrtcActions.setWebrtcSenderStatus(WEBRTC_STATUS.PENDING));
    await getVstConfig();
    /**
     * get 720p, 1080p resolution from webcam.
     * enable echoCancellation explicitly
     */
    const constraints = {
      audio: { echoCancellation: false },
      video: {
        width: { min: 1280, ideal: 1280, max: 1920 },
        height: { min: 720, ideal: 720, max: 1080 },
        frameRate: {
          ideal: 30,
          max: 30,
        },
      },
    };
    if(maxFps.current != null) {
      constraints.video.frameRate = {
        ideal : maxFps.current,
        max: maxFps.current
      }
    }
    console.log("constraints: ", constraints);
    navigator.mediaDevices
      .getUserMedia(constraints)
      // eslint-disable-next-line no-use-before-define
      .then(startWebrtcConnection)
      .catch((err) => {
        console.log("WEBRTC SENDER: Failed to access mic", err);
        enqueueSnackbar("Failed to access mic", { variant: "error" });
        dispatch(webrtcActions.setWebrtcSenderStatus(WEBRTC_STATUS.ERROR));
      });
  };

  const getVstConfig = async () => {
    await axios
    .get(
      `${uiServerOrVstEndpointString}${vmsOrApiString}/vst/settings`
    )
    .then((response) => {
      console.log(response.data);
      // read VST settings and set webrtc sender parameters
      const vstSettings = response.data;
      if(Object.prototype.hasOwnProperty.call(vstSettings, "webrtc_in_audio_sender_max_bitrate")) {
        audioBitrate.current = vstSettings.webrtc_in_audio_sender_max_bitrate;
      }
      if(Object.prototype.hasOwnProperty.call(vstSettings, "webrtc_in_video_degradation_preference")) {
        degradationPreference.current = vstSettings.webrtc_in_video_degradation_preference ;
      }
      if(Object.prototype.hasOwnProperty.call(vstSettings, "webrtc_in_video_sender_max_framerate")) {
        maxFps.current = vstSettings.webrtc_in_video_sender_max_framerate;
      }
      console.log("audioBitrate", audioBitrate.current);
      console.log("degradationPreference", degradationPreference.current);
      console.log("maxFps", maxFps.current);
    })
    .catch((rejectedValueOrSerializedError) => {
      console.error(rejectedValueOrSerializedError);
    });
  }

  const startWebrtcSender = () => {
    handleRecording();
  };

  const stopWebrtcSender = () => {
    // eslint-disable-next-line no-use-before-define
    stopWebrtcConnection();
  };

  const toggleMicrophone = () => {
    dispatch(micEnabledSliceActions.toggleMic())
    // if(stream && stream.getAudioTracks()[0]) {
    //   console.log('current state: ', micEnabledState)
    //   // setMicEnabled(!stream.getAudioTracks()[0].enabled);
    //   if (micEnabledState === true) {
    //     dispatch(micEnabledSliceActions.disableMic())
    //     stream.getAudioTracks()[0].enabled = false;
    //   } else if (micEnabledState === false) {
    //     dispatch(micEnabledSliceActions.enableMic())
    //     stream.getAudioTracks()[0].enabled = true;
    //   }
    //   // dispatch(micEnabledSliceActions.toggleMic())
    //   // stream.getAudioTracks()[0].enabled = !stream.getAudioTracks()[0].enabled;
    //   console.log('new state: ', micEnabledState)
    // }
  }

  const handleVideoAudioRecording = () => {
    if (
      webrtcStatus !== WEBRTC_STATUS.PENDING &&
      webrtcStatus !== WEBRTC_STATUS.CONNECTED
    ) {
      if (pc.current != null) {
        stopWebrtcSender();
      }
      startWebrtcSender();
    } else {
      toggleMicrophone();
    }
  };

  const addIceCandidate = (candidate) => {
    const jsonData = {
      peerid: pc.current.peerId.toString(),
      candidate,
    };
    console.debug("WEBRTC SENDER: calling vms/addIceCandidate with ", jsonData);
    const promise = axios(
      `${uiServerOrVstEndpointString}${vmsOrApiString}/addIceCandidate`,
      {
        method: "post",
        data: jsonData,
      }
    );
    promise.then((response) => {
      console.log("WEBRTC SENDER: added ice candidate ?", response.data);
    });
    promise.catch((e) => {
      console.error("WEBRTC SENDER: addIceCandidate error", e);
    });
  };

  const onReceiveCandidate = (response) => {
    const candidates = response.data;
    console.debug(
      `Received candidates from VMS: ${JSON.stringify(candidates)}`
    );
    if (candidates) {
      console.debug(
        "WEBRTC SENDER: Creating RTCIceCandidate from each received candidate.."
      );
      for (let i = 0; i < candidates.length; i += 1) {
        const candidate = new RTCIceCandidate(candidates[i]);

        console.debug(
          `Adding ICE candidate - ${i} :${JSON.stringify(candidate)}`
        );
        pc.current.addIceCandidate(
          candidate,
          () => {
            console.debug(`addIceCandidate OK - ${i}`);
          },
          (error) => {
            console.debug(`addIceCandidate error - ${i}`, error);
          }
        );
      }
    }
  };

  const getIceCandidate = () => {
    console.debug("WEBRTC SENDER: calling api/getIceCandidate");
    axios
      .get(
        `${uiServerOrVstEndpointString}${vmsOrApiString}/getIceCandidate?peerid=${pc.current.peerId}`
      )
      .then(onReceiveCandidate)
      .catch((e) => {
        console.error("WEBRTC SENDER: getIceCandidate error", e);
      });
  };

  const setRemoteDescription = (sessionDescriptionAnswer) => {
    if(audioBitrate.current != null) {
      console.debug("modified audio bitrate", audioBitrate.current);
      sessionDescriptionAnswer.sdp = sessionDescriptionAnswer.sdp.replace(`useinbandfec=1', 'useinbandfec=1; maxaveragebitrate=${Number(audioBitrate.current)*2}`);
    }
    pc.current
      .setRemoteDescription(new RTCSessionDescription(sessionDescriptionAnswer))
      .then(() => {
        earlyCandidates.current.forEach(addIceCandidate);
        getIceCandidate();
      })
      .catch((e) => {
        console.error("WEBRTC SENDER: onReceiveCall: ", e);
      });
  };

  const sendSessionDescriptionToVst = (sessionDescription) => {
    const sessionDescriptionPayload = {
      peerid: pc.current.peerId.toString(),
      is_client: true,
      options: {
        quality: "auto",
        rtptransport: "udp",
        timeout: 60,
      },
      sessionDescription,
    };
    console.debug(
      "WEBRTC SENDER: Payload of stream start: ",
      sessionDescriptionPayload
    );

    const promise = axios(
      `${uiServerOrVstEndpointString}${vmsOrApiString}/stream/start`,
      {
        method: "post",
        data: sessionDescriptionPayload,
      }
    );
    promise.then((response) => {
      const sessionDescriptionAnswer = response.data;
      connectionId.current = response.data.deviceId;
      console.debug(
        "Received response of stream start: ",
        sessionDescriptionAnswer
      );
      console.debug(
        "WEBRTC SENDER: Signaling state is ",
        pc.current.signalingState
      );
      if (pc.current.signalingState === "have-local-offer") {
        console.debug("WEBRTC SENDER: OK signalingState");
      } else {
        console.debug("WEBRTC SENDER: unexpected signalingState");
      }
      setRemoteDescription(sessionDescriptionAnswer);
    });
    promise.catch((e) => {
      console.error("WEBRTC SENDER: wertc error: ", e);
      dispatch(webrtcActions.setWebrtcSenderStatus(WEBRTC_STATUS.ERROR));
      // eslint-disable-next-line no-use-before-define
    });
  };

  const createOffer = () => {
    // add tracks before creating offer
    stream.getTracks().forEach((track) => pc.current.addTrack(track, stream));
    pc.current
      .createOffer(OFFER_OPTIONS)
      .then((sessionDescription) => {
        console.debug("WEBRTC SENDER: session Description", sessionDescription);
        pc.current.setLocalDescription(sessionDescription);
        return sessionDescription;
      })
      .then((sessionDescription) => {
        sendSessionDescriptionToVst(sessionDescription);
      })
      .catch((error) => {
        console.error("WEBRTC SENDER: Failed to create offer", error);
      });
  };

  const onConnectionStateChange = () => {
    // FireFox does not support onConnectionStateChange event
    console.info(
      "WEBRTC RECEIVER: connection state change: ",
      pc.current.connectionState
    );
    if (pc.current.connectionState === "disconnected") {
      console.debug("WEBRTC SENDER: Lost peer connection...");
    }
  };

  const onSignalingStateChange = () => {
    console.info(
      "WEBRTC RECEIVER: signaling state change: ",
      pc.current.signalingState
    );
  };

  const onIceGatheringStateChange = () => {
    console.info(
      "WEBRTC RECEIVER: gathering state change: ",
      pc.current.iceGatheringState
    );
  };

  const onIceCandidateError = (e) => {
    console.debug("WEBRTC SENDER: onIceCandidateError: ", e);
    if (e.errorCode !== 701) {
      console.error(
        `${e.errorText} error code ${e.errorCode} and url ${e.url}`,
        "onIceCandidateError"
      );
    }
    if (e.errorCode === 701) {
      console.debug(
        "error code is 701 that means DNS failed for ipv6, harmless error"
      );
    }
  };

  const onIceConnectionStateChange = () => {
    console.info(
      "ice connection state change: ",
      pc.current.iceConnectionState
    );
    if (pc.current && pc.current.iceConnectionState === "new") {
      getIceCandidate();
    }
    if (pc.current.iceConnectionState === "failed") {
      dispatch(webrtcActions.setWebrtcSenderStatus(WEBRTC_STATUS.ERROR));
    }
    if (pc.current.iceConnectionState === "disconnected") {
      dispatch(webrtcActions.setWebrtcSenderStatus(WEBRTC_STATUS.ERROR));
    }
    if (pc.current.iceConnectionState === "connected") {
      dispatch(webrtcActions.setWebrtcSenderStatus(WEBRTC_STATUS.CONNECTED));
    }
  };

  const onIceCandidate = (event) => {
    if (!event.candidate) {
      console.debug(
        "received null candidate, that means its the last candidate"
      );
      return;
    }
    if (event.candidate) {
      if (event.candidate.candidate.length === 0) {
        console.debug(
          "Recevived empty string for candidate - client is firefox"
        );
        return;
      }
      console.debug(
        "received candidate inside onIceCandidate callback: ",
        event.candidate.candidate
      );
      // If a srflx candidate was found, notify that the STUN server works!
      if (event.candidate.type === "srflx") {
        console.debug(
          "WEBRTC SENDER: The STUN server is reachable for this candidate!"
        );
        console.debug(`Public IP Address is: ${event.candidate.address}`);
        dispatch(
          ipAddressSliceActions.updateIPAddress(
            event.candidate.address
          )
        );
      }
      // If a relay candidate was found, notify that the TURN server works!
      if (event.candidate.type === "relay") {
        console.debug(
          "WEBRTC SENDER: The TURN server is reachable for this candidate!"
        );
      }
      if (pc.current && pc.current.currentRemoteDescription) {
        addIceCandidate(event.candidate);
      } else {
        earlyCandidates.current.push(event.candidate);
      }
    }
  };

  const createRTCPeerConnection = (iceServers) => {
    console.debug("WEBRTC SENDER: ICE Servers: ", iceServers);
    try {
      const peerConnection = new RTCPeerConnection(
        { iceServers },
        PEER_CONNECTION_OPTIONS
      );
      peerConnection.peerId = peerId.current;
      peerConnection.onicecandidate = onIceCandidate;
      peerConnection.oniceconnectionstatechange = onIceConnectionStateChange;
      peerConnection.onicecandidateerror = onIceCandidateError;
      peerConnection.onicegatheringstatechange = onIceGatheringStateChange;
      peerConnection.onsignalingstatechange = onSignalingStateChange;
      peerConnection.onconnectionstatechange = onConnectionStateChange;
      pc.current = peerConnection;
    } catch (error) {
      console.error(`Failed to create RTC peer connection.`, error);
      dispatch(webrtcActions.setWebrtcSenderStatus(WEBRTC_STATUS.ERROR));
    }
    createOffer();
  };

  const onDeviceFetch = () => {
    console.log("WEBRTC SENDER: Stream ", stream);
    peerId.current = Math.random();
    axios
      .get(
        `${uiServerOrVstEndpointString}${vmsOrApiString}/getIceServers?peerId=${peerId.current}`
      )
      .then((response) => {
        console.log(response.data);
        createRTCPeerConnection(response.data.iceServers);
      })
      .catch((rejectedValueOrSerializedError) => {
        console.error(rejectedValueOrSerializedError);
        dispatch(webrtcActions.setWebrtcSenderStatus(WEBRTC_STATUS.ERROR));
      });
  };

  const startWebrtcConnection = (CameraStream) => {
    const videoTracks = CameraStream.getVideoTracks();
    const audioTracks = CameraStream.getAudioTracks();
    if (videoTracks.length > 0) {
      console.log(`WEBRTC SENDER: Using video device: ${videoTracks[0].label}`);
    }
    if (audioTracks.length > 0) {
      console.log(`WEBRTC SENDER: Using audio device: ${audioTracks[0].label}`);
      dispatch(micEnabledSliceActions.enableMic())
      // setMicEnabled(audioTracks[0].enabled);
    }
    /**
     * The track should be treated as if video details are extra important.
     * This is generally applicable to presentations or web pages with text
     * content, painting or line art. This setting would normally optimize
     * for detail in the resulting individual frames rather than smooth playback.
     * Artefacts from quantization or downscaling that make small text or line
     * art unintelligible should be avoided.
     */
    videoTracks.forEach((track) => {
      if(degradationPreference.current != null) {
        console.log("setting degradation preference: ", degradationPreference.current);
        track.contentHint = degradationPreference.current === "resolution" ? "motion" : "detail";
      }
      else {
        // default setting is to reduce resolution
        console.log("setting default degradation preference");
        track.contentHint = "motion";
      }
      console.log("video track: ", track);
    });
    /**
     * The track should be treated as if it contains speech data.
     * Consuming this signal it may be appropriate to apply noise
     * suppression or boost intelligibility of the incoming signal.
     */
    audioTracks.forEach((track) => {
      track.contentHint = "speech";
      console.log("audio track: ", track);
    });
    stream = CameraStream;
    onDeviceFetch();
  };

  const stopWebrtcConnection = () => {
    if (pc.current != null) {
      const jsonData = {
        peerid: pc.current.peerId.toString(),
      };
      pc.current.close();
      pc.current = null;
      peerId.current = null;
      earlyCandidates.current = [];
      console.debug("WEBRTC SENDER: calling api/stream/stop with ", jsonData);
      dispatch(
        webrtcActions.setWebrtcSenderStatus(WEBRTC_STATUS.UNINITIALIZED)
      );

      const promise = axios(
        `${uiServerOrVstEndpointString}${vmsOrApiString}/stream/stop`,
        {
          method: "post",
          data: jsonData,
        }
      );
      promise.then((response) => {
        console.log("WEBRTC SENDER: stream stop ?", response.data);
      });
      promise.catch((e) => {
        console.error("WEBRTC SENDER: stream stop error", e);
      });
    }
  };

  const getMicIconColor = () => {
    if(webrtcStatus === WEBRTC_STATUS.CONNECTED) {
      if(webrtcNetworkQuality !== "low") {
        return "primary.main"; // att blue
      }
        return "#FEC119"; // yellow
    }
    return "#F03232"; // red
  }

  const toggleCCStatus = () => {
    dispatch(
      ccStatusActions.toggleCaptions()
    )
    console.log("Captions status changed");
  }

  return (
    <Grid
      item
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          align: "center",
        }}
      >
        <WebrtcDataChannel />
        {(config.enableCamera === false) &&
          <StartConversation />
        }
        <Button
          sx={{
            background: getMicIconColor(),
            marginRight: 1,
            "&:hover": {
              backgroundColor: getMicIconColor(),
            },
            "&.Mui-disabled": {
              backgroundColor: "primary.main",
              color: "#ffffff"
            }
          }}
          onClick={handleVideoAudioRecording}
          variant='contained'
          disabled={webrtcStatus === WEBRTC_STATUS.PENDING || micDisableButton}
        >
          {webrtcStatus === WEBRTC_STATUS.CONNECTED && micEnabledState && <MicIcon />}
          {webrtcStatus === WEBRTC_STATUS.CONNECTED && !micEnabledState && <MicOffIcon />}
          {webrtcStatus === WEBRTC_STATUS.PENDING && <HourglassEmptyIcon />}
          {webrtcStatus === WEBRTC_STATUS.ERROR && <ErrorOutlineIcon />}
          {webrtcStatus === WEBRTC_STATUS.UNINITIALIZED && <MicOffIcon />}
        </Button>
        <Button
          sx={{
            background: "primary.main",
            marginRight: 1,
            "&:hover": {
              backgroundColor: "primary.main",
            },
          }}
          onClick={toggleCCStatus}
          variant='contained'
        >
          {ccState ? <ClosedCaptionIcon /> : <ClosedCaptionDisabledIcon />}
        </Button>
        <Button
          size='medium'
          variant='outlined'
          color='secondary'
          sx={{ width: 170, height: 33, color: "black" }}
          startIcon={<VolumeUpOutlinedIcon />}
        >
          {ovState}
        </Button>
      </Box>
    </Grid>
  );
}

export default WebrtcSender;
