/* eslint-disable no-unused-vars */
import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import WebrtcSenderAndBotState from "../../connection/webrtc/webrtc-sender/WebrtcSender";
import CountdownTimer from "../../utils/Countdown";
import config from "../../config/config";
import logo_quantiphi from "../../assets/logo-quantiphi.png";
// import logo_gcp from "../../assets/logo-gcp.png";
import nvidia_logo from "../../assets/logo-nvidia.png";

/* Topbar Component */
function TopBar() {
  const height = window.innerHeight > window.innerWidth ? "5vh" : "7.5vh"
  return (
    <Grid item xs={12} sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          align: "center",
          paddingLeft: { xs: 0, md: 2.6 },
          paddingRight: { xs: 0, md: 2.6 },
          paddingTop: { xs: 0, md: 1.9 },
          paddingBottom: { xs: 0, md: 1.9 },
          background: "#eceff1",
          borderTop: "5px solid transparent",
          borderImage: "linear-gradient(90deg, rgba(32, 178, 131, 1) 0%, rgba(36, 61, 127, 1) 100%)",
          borderImageSlice: "1",
          height
        }}>
      <Box component='div' 
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            paddingRight: { xs: 0, md: 12 },
          }}>
          <img src={logo_quantiphi} alt="" height="40" />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <img src={nvidia_logo} alt="" height="29" />
        {config.enableCountdown &&
        <CountdownTimer />}
      </Box>
      <WebrtcSenderAndBotState />
    </Grid>
  );
}

export default TopBar;
