/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
/* eslint-disable no-console */
import React, { useEffect, useRef } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import jwt_decode from "jwt-decode";
import App from "../../App";
import LoadingBackdrop from "../../features/backdrops/loading-backdrop/LoadingBackdrop";
import ErrorMessage from "../../features/error/ErrorMessage";
import { accessAndIdTokenSliceActions } from "./AccessAndIdTokenSlice";
import { sessionSliceActions } from "../sessionSlice";
import config, { SESSION_STATUS } from "../../config/config";
import useInterval from "../../utils/useInterval";

/**
 * This is a one time process to get one time usable authorization code
 * from the URL. After getting the authorization code attempt to get
 * access token and id token
 */
export default function AccessAndIdTokenWrapper(props) {
  // We can also use authorizationCode redux state
  const { authorizationCode } = props;
  const dispatch = useDispatch();
  const executeOnce = useRef(true);
  const accessAndIdToken = useSelector((state) => state.accessAndIdTokenState);
  const sessionToken = useSelector((state) => state.session.sessionToken);
  const sessionStatus = useSelector((state) => state.session.sessionStatus);
  const sessionErrorCode = useSelector((state) => state.session.errorCode);

  useEffect(() => {
    if (accessAndIdToken.idToken != null) {
      getSessionToken();
    }
  }, [accessAndIdToken]);

  const getSessionToken = () => {
    console.log("Trying to get session token with ", accessAndIdToken);
    let headers;
    if (authorizationCode != null) {
      headers = { Authorization: `Bearer ${accessAndIdToken.idToken}` };
    } else {
      headers = {};
    }
    console.log("Session token headers: ", headers);
    const promisee = axios(`${config.uiServerEndpoint}/token`, {
      method: "get",
      headers,
    });
    promisee.then((response) => {
      console.log("session /token response:", response.data);
      console.debug("SESSION TOKEN: ", response.data.token);
      console.debug("SESSION TTL: ", response.data.token_ttl);
      const sessionState = {
        sessionStatus: SESSION_STATUS.VALID,
        errorCode: 200,
        errorMessage: "",
        sessionToken: response.data.token,
        sessionTtl: response.data.token_ttl,
      };
      dispatch(sessionSliceActions.setSession(sessionState));
    });
    promisee.catch((e) => {
      console.error("Failed to refresh session token !!!", e);
    });
  };
  /**
   * By default axios sends data in JSON format. To send x-www-form-urlencoded data we need
   * to use URLSearchParams or qs.stringify.
   */
  const getAccessAndIdpToken = () => {
    console.log("Getting access and idp token for first time !!!");
    const jsonData = {
      grant_type: "authorization_code",
      code: authorizationCode,
      client_id: "BDCpX-YWzn74Lk_52UV4PU9VF76szPjNDIVBFA2RbgI",
      code_verifier: "dBjftJeZ4CVP-mB92K27uhbUJU1p1r_wW1gFWFOEjXk",
    };
    const promise = axios(`${config.authServer}/token`, {
      method: "post",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: new URLSearchParams(jsonData),
    });
    promise.then((res) => {
      console.log("application/x-www-form-urlencoded: ", res);
      if (
        res != null &&
        res.data != null &&
        res.data.access_token != null &&
        res.data.id_token != null
      ) {
        const accessAndIdTokenData = {
          accessToken: res.data.access_token,
          idToken: res.data.id_token,
        };
        console.debug("ACCESS TOKEN: ", accessAndIdTokenData.accessToken);
        console.debug("ID TOKEN: ", accessAndIdTokenData.idToken);
        dispatch(
          accessAndIdTokenSliceActions.setAccessAndIdToken(accessAndIdTokenData)
        );
        // useEffect will trigger to call session Token
      }
    });
    promise.catch((e) => {
      console.error("unable to get access token and id token ", e);
    });
  };

  const accessAndIdpTokenRefresher = () => {
    console.log("refreshing access and idp token !!!");
    const promisee = axios(`${config.authServer}/client_token`, {
      method: "get",
      headers: { Authorization: `Bearer ${accessAndIdToken.accessToken}` },
    });
    promisee.then((response) => {
      console.log("/client_token response:", response.data);
      console.debug("CLIENT TOKEN: ", response.data.client_token);
      console.debug("EXPIRES IN: ", response.data.expires_in);
      const decodedJwtToken = jwt_decode(accessAndIdToken.idToken);
      // get new access token and id token
      const jsonData = {
        grant_type: "urn:ietf:params:oauth:grant-type:client_token",
        client_token: response.data.client_token,
        client_id: "BDCpX-YWzn74Lk_52UV4PU9VF76szPjNDIVBFA2RbgI",
        sub: decodedJwtToken.sub,
      };
      const promise = axios(`${config.authServer}/token`, {
        method: "post",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Host: "stg.login.nvidia.com",
        },
        data: new URLSearchParams(jsonData),
      });
      promise.then((res) => {
        console.log("application/x-www-form-urlencoded: ", res);
        if (
          res != null &&
          res.data != null &&
          res.data.access_token != null &&
          res.data.id_token != null
        ) {
          const accessAndIdTokenData = {
            accessToken: res.data.access_token,
            idToken: res.data.id_token,
          };
          console.debug("NEW ACCESS TOKEN: ", accessAndIdTokenData.accessToken);
          console.debug("NEW ID TOKEN: ", accessAndIdTokenData.idToken);
          dispatch(
            accessAndIdTokenSliceActions.setAccessAndIdToken(
              accessAndIdTokenData
            )
          );
        }
      });
    });
    promisee.catch((e) => {
      console.error("failed to refresh access and idp token !!!", e);
    });
  };

  if (executeOnce.current) {
    console.log("Execute once !!!");
    executeOnce.current = false;
    // initial token getters, called only once
    // TODO: will change in tokens effect webrtc connection ?
    // token refreshers
    if (authorizationCode != null) {
      getAccessAndIdpToken();
    } else {
      getSessionToken();
    }
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useInterval(() => {
    if (accessAndIdToken.idToken != null) {
      accessAndIdpTokenRefresher();
    }
  }, config.accessAndIdTokenRefreshInterval);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useInterval(() => {
    if (accessAndIdToken.idToken != null || authorizationCode == null) {
      console.log("refresh session token !!!");
      getSessionToken();
    }
  }, config.sessionTokenRefreshInterval);

  return (
    <div>
      {
        (
          (
            (
              accessAndIdToken.accessToken != null &&
              accessAndIdToken.idToken != null &&
              sessionToken != null
            ) ||
            (
              sessionToken != null &&
              authorizationCode == null
            )
          ) &&
          sessionStatus === SESSION_STATUS.VALID
        ) && <App />
      }

      {
        (
          (
            accessAndIdToken.accessToken == null ||
            accessAndIdToken.idToken == null ||
            sessionToken == null
          ) &&
          (
            sessionToken == null ||
            authorizationCode != null
          ) &&
          sessionStatus === SESSION_STATUS.VALID
        ) && <LoadingBackdrop />
      }

      {sessionStatus === SESSION_STATUS.INVALID && <ErrorMessage status={sessionErrorCode} />}
    </div>
  );
}
