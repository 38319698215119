import axios from "axios";
import store from "../store/store";
import config from "../config/config";

/* Submit feedback to server and save it in DB */
const submitFeedback = async (rating) => {
    const state = store.getState();
    try {
        const axiosConfig = {
            method: 'post',
            url: `${config.uiServerEndpoint}/manage/rating`,
            data: {
                uuid: state.uuid.data.session_id,
                score: rating,
                state: "final"
            }
        }
        const response = await axios(axiosConfig);
        return response;
    } catch (error) {
        return ({
            'error': error.message
        })
    }
}

const submitResponseFeedback = async (uid, chat, satisfied) => {
    try {
        const state = store.getState();
        const axiosConfig = {
            method: 'post',
            url: `${config.uiServerEndpoint}/manage/rating`,
            data: {
                uuid: state.uuid.data.session_id,
                chat_id: uid,
                chat,
                satisfied,
                state: "intermediate"
            }
        }
        const response = await axios(axiosConfig);
        return response;
    } catch (error) {
        return ({
            'error': error.message
        })
    }
}

export { submitFeedback, submitResponseFeedback}