/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable no-console */
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route, Navigate } from "react-router-dom";
import { ReadyState } from "react-use-websocket";
import { ErrorBoundary } from "react-error-boundary";
import Grid from "@mui/material/Grid";
import Fab from '@mui/material/Fab';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Rating from '@mui/material/Rating';
import ChatIcon from '@mui/icons-material/Chat';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import { ccStatusActions } from "./features/captions/CaptionsToggleSlice";
import TopBar from "./features/top-bar/TopBar";
import WebSocketConnection from "./connection/websocket/websocket";
import Webrtc from "./connection/webrtc/webrtc-receiver/webrtcReceiver";
import WaitingMessage from "./features/waiting-message/waitingMessage";
import ErrorFallback from "./features/error/ErrorFallback";
import config from "./config/config";
import { drawerWidth, Main, DrawerHeader, fabStyle, modalStyle, handleWindowSizeChange } from "./utils/styleUtils";
import { feedbackSliceActions } from "./features/feedback/FeedbackSlice";
import { chatbotSliceActions } from "./features/chatbox/chatboxSlice";
import { submitFeedback, submitResponseFeedback } from "./utils/apiUtils";
import { micEnabledSliceActions } from "./features/top-bar/micEnabledSlice";

function App() {
  /* Make websocket connection and show the UI if connection is successful */
  WebSocketConnection();
  const dispatch = useDispatch();
  const scrollRef = useRef(null);
  const [chatOpen, setChatOpen] = useState(false);
  const [userRating, setUserRating] = useState(null);
  const [feedbackLoading, setFeedbackLoading] = useState(false);
  const readyState = useSelector((state) => state.uuid.status);
  const chats = useSelector((state) => state.chatboxSlice.chats);
  const feedback = useSelector((state) => state.feedback.feedback);
  const connectionStatus = {
    [ReadyState.CONNECTING]: "Connecting",
    [ReadyState.OPEN]: "Open",
    [ReadyState.CLOSING]: "Closing",
    [ReadyState.CLOSED]: "Closed",
    [ReadyState.UNINSTANTIATED]: "Uninstantiated",
  }[readyState];

  /* Display the chat box widget */
  const handleDrawerOpen = () => {
    setChatOpen(true);
    scrollRef.current.scrollIntoView({ behavior: 'smooth'})
    dispatch(
      ccStatusActions.disableCaption()
    );
  };

  /* Hide the chat box drawer */
  const handleDrawerClose = () => {
    setChatOpen(false);
    dispatch(
      ccStatusActions.enableCaption()
    );
  };

  /* Display rating popup after feddback action in received */
  const handleFeedback = async (e, rating) => {
    e.preventDefault();
    setUserRating(rating);
    setFeedbackLoading(true);
    const response = await submitFeedback(rating);
    console.log(response);
    dispatch(feedbackSliceActions.disableFeedback());
    setFeedbackLoading(false);
    dispatch(chatbotSliceActions.clearAllChats());
    setUserRating(null);
    setChatOpen(false);
    dispatch(
      ccStatusActions.enableCaption()
    );
  }

  /*  */
  const handleResponseFeedback = async (e, uid, chat, satisfied) => {
    e.preventDefault();
    dispatch(chatbotSliceActions.markFeedbackSubmitted({uid}));
    const response = await submitResponseFeedback(uid, chat, satisfied);
    console.log(response);
  } 

    /* Logs websocket connection when connectionStatus is changed */
  useEffect(() => {
    console.log("Websocket is currently", connectionStatus);
  }, [connectionStatus]);

    /* Handles automatic scroll in chat box drawer */
  useEffect(() => {
    if (scrollRef !== null && chats.length > 1) {
      scrollRef.current.scrollIntoView({ behavior: 'smooth'})
    }
  }, [chats])

    /* Handle window size change */
  useEffect(() => {
    handleWindowSizeChange();
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    function handlekeydownEvent(event) {
      console.log(event);
      if (event.code === "Space" || event.code === "Backspace") {
        dispatch(micEnabledSliceActions.toggleMic());
      }
    }
  
    document.addEventListener('keyup', handlekeydownEvent)
    return () => {
      document.removeEventListener('keyup', handlekeydownEvent)
    }
  }, [])
  
  if (typeof VST_IP !== "undefined") {
    // eslint-disable-next-line no-undef
    console.debug("VST IP", VST_IP);
  }
  if (typeof VST_PORT !== "undefined") {
    // eslint-disable-next-line no-undef
    console.debug("VST PORT", VST_PORT);
  }

  return (
    <div>
      <div className='App'>
        <div id='main-container'>
          {/* Main div that displays the avatar */}
          <Main open={chatOpen}>
            <Grid container justifyContent="center">
              <ErrorBoundary FallbackComponent={ErrorFallback}>
                <TopBar />
                <Routes>
                  <Route path='/' element={<Navigate replace to='/welcome' />}
                  />
                  <Route path='/index.html' element={<Navigate replace to='/welcome' />}
                  />
                  <Route exact path='/welcome' element={<Webrtc />} />
                </Routes>
                <WaitingMessage />
              </ErrorBoundary>
            </Grid>
          </Main>
          {/* Chat icon to display / hide the chat box drawer */}
          <Box sx={{ '& > :not(style)': { m: 1 } }}>
            {chats.length > 0 && chatOpen === false && config.enableChatWidget &&
            <Fab style={fabStyle} variant="extended" size="large" color="primary" aria-label="add" id="basic-button"
              onClick={handleDrawerOpen}>
              <ChatIcon sx={{ fontSize: "48px" }} />
            </Fab>
            }
          </Box>
        </div>
        {/* Chatbox drawer */}
        <Drawer sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            backgroundColor: "#121212",
          },
        }} variant="persistent" anchor="right" open={chatOpen}>
          <div style={{position: "fixed", zIndex: "1", backgroundColor: "#121212", width: drawerWidth, height: 70.38}}>
            <DrawerHeader>
              <IconButton onClick={handleDrawerClose} sx={{color: "#eceff1"}}>
                <ChevronRightIcon sx={{ fontSize: "36px" }} />
              </IconButton>
            </DrawerHeader>
          </div>
          <div style={{marginTop: "70px"}}>
            <List>
              {chats.map((chat, idx) => (
              <span key={chat.uuid} id={idx} ref={idx===chats.length - 1 ? scrollRef : null}>
                {chat.speaker === "tts" ?
                /* TTS responses */
                <Grid>
                  <Grid>
                    <Box sx={{
                        borderRadius: 3,
                        p:1,
                        mx:2,
                        mt:2,
                        display: 'inline-flex',
                        backgroundColor: 'primary.main',
                        maxWidth: drawerWidth,
                      }}>
                      <Typography variant="body1" sx={{maxWidth: drawerWidth * 0.9}}>
                        {chat.chat}
                      </Typography>
                    </Box>
                  </Grid>
                  {chat.feedback === false &&
                  <Grid>
                    <Box sx={{
                      borderRadius: 2,
                      mx:2,
                      display: 'inline-flex',
                      backgroundColor: 'rgba(250, 250, 250, 0.9)',
                      maxWidth: drawerWidth,
                    }}>
                      <Typography variant="caption"
                        sx={{maxWidth: drawerWidth * 0.9, px: 1, display: "flex", alignItems: "center", flexDirection: "row"}}
                        color="#000000">
                        <IconButton color="success" component="label"
                          sx={{p: 0, '&:hover': {transform: "scale3d(1.1, 1.1, 1) rotate(-5deg)"},}} onClick={(event)=>
                          handleResponseFeedback(event, chat.uuid, chat.chat, 1)} >
                          <ThumbUpOffAltIcon />
                        </IconButton>
                        &nbsp;&nbsp;&nbsp;
                        <IconButton color="error" component="label"
                          sx={{p: 0, '&:hover': {transform: "scale3d(1.1, 1.1, 1) rotate(5deg)"},}} onClick={(event)=>
                          handleResponseFeedback(event, chat.uuid, chat.chat, 0)}>
                          <ThumbDownOffAltIcon />
                        </IconButton>
                      </Typography>
                    </Box>
                  </Grid>
                  }
                </Grid>
                :
                /* ASR Responses */
                <Grid>
                  <Typography variant="body1" sx={{px:2}} align="right" color="#000000">
                    <Box sx={{
                      borderRadius: 3,
                      p:1,
                      mt:2,
                      display: 'inline-flex',
                      align:"right",
                      backgroundColor: 'white',
                      maxWidth: drawerWidth
                    }}>
                      {chat.chat}
                    </Box>
                  </Typography>
                </Grid>
                }
              </span>
              ))}
            </List>
          </div>
        </Drawer>
        {/* Rating modal, enabled when feedback action is received */}
        <Modal open={feedback} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
          <Box sx={modalStyle}>
            <Typography id="modal-modal-title" variant="h6" component="h2" sx={{textAlign: "center"}}>
              Rate your experience
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 1, textAlign: "center" }}>
              <Rating name="simple-controlled" value={userRating} sx={{fontSize: 48}} onChange={(e, rating)=>
                handleFeedback(e, rating)} disabled={feedbackLoading} />
            </Typography>
          </Box>
        </Modal>
      </div>
    </div>
  );
}

export default App;