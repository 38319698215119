
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
import PersonIcon from '@mui/icons-material/Person';
import VolumeUpOutlinedIcon from "@mui/icons-material/VolumeUpOutlined";
import { asrSliceActions } from "../asrText/asrSlice";
import { ttsSliceActions } from "../ttsText/ttsSlice";
import { preProcessASR, preProcessTTS } from "../../utils/utils";

const captionStyles = {
  paddingTop: "5px",
  display: "flex",
  maxWidth: "100vw"
};

const captionBodyStyle = {
  position: "absolute",
  bottom: 5,
  align: "center",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: "48vw"
};

const captionsBaseStyle = {
  background: "rgba(0, 0, 0, 0.6)",
  textAlign: "center",
  margin: "auto",
};

/* Component to display cc in the UI */
function CCState() {
  const dispatch = useDispatch();
  const [timeout, setTimeoutVal] = useState(false);
  const asrState = useSelector((state) => state.asrState.asr);
  const ttsState = useSelector((state) => state.ttsState.tts);
  const temp = "As per our records, your address is House number 42, Abc Street, Marlborough, the phone number listed is 2346534, and the email ID associated with your account is christine at web mail dot com. Is that correct?"
  const temp1 = "As per our records, your address is House number 42, Abc Street, Marlborough, the phone number listed is 2346534, and the email ID associated with your account is christine@webmail.com. Is that correct?"

  const getInterval = words => {
    const interval = words.split(" ").length / 2.1 * 1000;
    if (interval < 2000) {
      return interval + 2000
    }
    return interval
  }

  useEffect(() => {
    setTimeoutVal(true);
  }, [asrState, ttsState]);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeoutVal(false);
      clearInterval(interval);
    }, getInterval(ttsState));
    return () => {
      clearInterval(interval);
    };
  }, [asrState, ttsState]);

  useEffect(() => {
    const cctimeout = setTimeout(() => {
      dispatch(asrSliceActions.clearAsrState());
      dispatch(ttsSliceActions.clearTtsstate());
    }, getInterval(ttsState))
    return () => {
      clearTimeout(cctimeout)
    }
  }, [asrState, ttsState])

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      <div style={captionBodyStyle}>
        {/* ASR CC */}
        {asrState != null && asrState.length > 0 && timeout && (
        <div style={captionStyles}>
          <div style={captionsBaseStyle}>
            <Typography sx={{ verticalAlign: "middle", display: "inline-flex", fontSize: 16,fontFamily: 'Poppins, sans-serif' }}>
              <PersonIcon sx={{ marginRight: 1 }} />
              {preProcessASR(asrState)}
            </Typography>
          </div>
        </div>
        )}
        {/* TTS CC */}
        {ttsState != null && ttsState.length > 0 && timeout && (
        <div style={captionStyles}>
          <div style={captionsBaseStyle}>
            <Typography sx={{ verticalAlign: "middle", display: "inline-flex", fontSize: 16,fontFamily: 'Poppins, sans-serif' }}>
              <VolumeUpOutlinedIcon sx={{ marginRight: 1 }} />
              {preProcessTTS(ttsState)}
            </Typography>
          </div>
        </div>
        )}
      </div>
    </>
  );
}

export default CCState;
