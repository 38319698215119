/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import Backdrop from "@mui/material/Backdrop";
import Typography from "@mui/material/Typography";
import React from "react";

export default function ErrorMessage(props) {
  const { status } = props;
  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open
    >
      <Typography variant='h4' color='primary.main'>
        {status === 401 && (
          <>Your session has expired, please refresh to start a new one.</>
        )}
        {status === 503 && (
          <>All bots are busy helping other people, please come back later.</>
        )}
        {status === 400 && (
          <>
            Invalid token, please clear the browser cookie and refresh the page.
          </>
        )}
        {status === 403 && (
          <>Unable to validate session, please contact Nvidia</>
        )}
      </Typography>
    </Backdrop>
  );
}
