/* eslint-disable react/prop-types */
import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

function ErrorFallback({ error, resetErrorBoundary }) {
  const navigate = useNavigate();
  const handleReset = () => {
    navigate("/WELCOME_VIEW");
    window.location.reload();
  };

  return (
    <div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open
      >
        <Box>
          <Typography variant='p'>
            {`Something went wrong: ${error.message}`}
          </Typography>
          <br />
          <Button onClick={resetErrorBoundary}>Try again</Button>
          <Button onClick={handleReset} color='error'>
            Reset
          </Button>
        </Box>
      </Backdrop>
    </div>
  );
}

export default ErrorFallback;
