/* eslint-disable no-console */
import axios from "axios";
import axiosRetry from "axios-retry";
import store from "../../store/store";
import { sessionSliceActions } from "../../session/sessionSlice";
import { SESSION_STATUS } from "../../config/config";

export default function interceptAndRetry() {
  axiosRetry(axios, {
    retries: 5,
    retryCondition: (e) => {
      if (e.response.status === 401) {
        // Show session expired page on 401 error
        // TODO: handle session Timeout better. Currently its not being used anywhere.
        window.sessionTimedOut = true;
        window.cart = null;
        window.totalOrderCost = null;
        store.dispatch(sessionSliceActions.setSession({
          sessionStatus: SESSION_STATUS.INVALID,
          errorCode: 401,
          errorMessage: "401 error",
          sessionToken: null,
          sessionTtl: 0,
        }));
      }
      if (e.response.status === 503) {
        // Show bots busy page on 503 error
        window.cart = null;
        window.totalOrderCost = null;
        store.dispatch(sessionSliceActions.setSession({
          sessionStatus: SESSION_STATUS.INVALID,
          errorCode: 503,
          errorMessage: "503 error",
          sessionToken: null,
          sessionTtl: 0,
        }));
      }
      if (e.response.status === 400 && (e.response.data.trim() === "Invalid Token" || e.response.data.trim() === "Client Token Missing")) {
        // Show invalid token error on 400 error
        window.cart = null;
        window.totalOrderCost = null;
        store.dispatch(sessionSliceActions.setSession({
          sessionStatus: SESSION_STATUS.INVALID,
          errorCode: 400,
          errorMessage: "400 error",
          sessionToken: null,
          sessionTtl: 0,
        }));
      }
      if (e.response.status === 403) {
        // Show validation error on 403 error
        window.cart = null;
        window.totalOrderCost = null;
        store.dispatch(sessionSliceActions.setSession({
          sessionStatus: SESSION_STATUS.INVALID,
          errorCode: 403,
          errorMessage: "403 error",
          sessionToken: null,
          sessionTtl: 0,
        }));
      }
      // retry on 429 error code
      return e.response.status === 429;
    },
    retryDelay: axiosRetry.exponentialDelay,
  });
}