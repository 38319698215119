/* eslint-disable no-prototype-builtins */
import { createSlice } from "@reduxjs/toolkit";

const micInitialState = {
    micEnabled: true,
    disableMicButton: false
};

/* Redux slice to save client IP address */
const micEnabledSlice = createSlice({
    name: "mic",
    initialState: micInitialState,
    reducers: {
        enableMic(state) {
            state.micEnabled = true
        },
        disableMic(state) {
            state.micEnabled = false
        },
        toggleMic(state) {
            state.micEnabled = !state.micEnabled
        },
        enableMicButton(state) {
            state.disableMicButton = false
        },
        disableMicButton(state) {
            state.disableMicButton = true
        }
    },
});

export const micEnabledSliceActions = micEnabledSlice.actions;

export default micEnabledSlice;
