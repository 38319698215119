import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import config, { HTTP_STATUS } from "../../config/config";

const postRoiEntryInitialState = {
    loading: null, /* status of HTTP request */
    data: [], /* response of HTTP request */
}

export const postRoiEntry = createAsyncThunk(
    "tokkio/postRoiEntry",
    async(_, { getState }) => {
        const state = getState();
        const postPayload = {
          stream_id: state.uuid.streamId.toString()
        };
        const { data } = await axios.post(
          `${config.uiServerEndpoint}/manage/hook/roiEntry`,
          postPayload,
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }
        );
        return data;
    }
);

const postRoiEntrySlice = createSlice({
    name: "postRoiEntry",
    initialState: postRoiEntryInitialState,
    reducers: {},
    extraReducers: {
      [postRoiEntry.pending](state) {
        state.loading = HTTP_STATUS.PENDING;
      },
      [postRoiEntry.fulfilled](state, { payload }) {
        state.loading = HTTP_STATUS.FULFILLED;
        state.data = payload
      },
      [postRoiEntry.rejected](state) {
        state.loading = HTTP_STATUS.REJECTED;
      },
    },
  }
);

export const postRoiEntryActions = postRoiEntrySlice.actions;
export default postRoiEntrySlice;