/* eslint-disable no-prototype-builtins */
import { createSlice } from "@reduxjs/toolkit";

const ovInitialState = {
  ov: ""
};

/* Redux slice to manage OvState */
const ovSlice = createSlice({
  name: "ov",
  initialState: ovInitialState,
  reducers: {
    setOvState(state, action) {
      state.ov = action.payload;
    }
  },
});

export const ovSliceActions = ovSlice.actions;

export default ovSlice;
