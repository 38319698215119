/* eslint-disable no-console */
import "./index.css";
import React from "react";
import ReactDOM from "react-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import * as serviceWorker from "./serviceWorker";
import tokkioTheme from "./theme/theme";
import store from "./store/store";
import AuthorizationCodeWrapper from "./session/AuthorizationCodeWrapper/AuthorizationCodeWrapper";
import SnackbarUtilsConfigurator from "./utils/Snackbar";
import interceptAndAppendHeaders from "./connection/axios-interceptors/interceptAndAppendHeaders";
import config from "./config/config";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";

interceptAndAppendHeaders();
// TODO: handle session timeout better
window.sessionTimedOut = false;
// replace console.* for disable log on production
if (!config.enableLogs) {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
  console.info = () => {};
}
ReactDOM.render(

    <Provider store={store}>
      <ThemeProvider theme={tokkioTheme}>
        <CssBaseline enableColorScheme />
        <BrowserRouter>
          <SnackbarProvider maxSnack={1} preventDuplicate>
            <SnackbarUtilsConfigurator />
            <AuthorizationCodeWrapper />
          </SnackbarProvider>
        </BrowserRouter>
      </ThemeProvider>
    </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
