/* eslint-disable no-prototype-builtins */
import { createSlice } from "@reduxjs/toolkit";


/* Redux slice to manage ASR state */
const asrInitialState = {
  asr: ""
};

const asrSlice = createSlice({
  name: "asr",
  initialState: asrInitialState,
  reducers: {
    setAsrState(state, action) {
      state.asr = action.payload;
    },
    clearAsrState(state) {
      state.asr = ""
    }
  },
});

export const asrSliceActions = asrSlice.actions;

export default asrSlice;
