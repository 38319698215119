/* eslint-disable no-prototype-builtins */
import { createSlice } from "@reduxjs/toolkit";

const sessionInitialState = {
  sessionStatus: null,
  errorCode: 200,
  errorMessage: "",
  sessionToken: null,
  sessionTtl: 0,
};

const sessionSlice = createSlice({
  name: "session",
  initialState: sessionInitialState,
  reducers: {
    setSession(state, action) {
      state.sessionStatus = action.payload.sessionStatus;
      state.errorCode = action.payload.errorCode;
      state.errorMessage = action.payload.errorMessage;
      state.sessionToken = action.payload.sessionToken;
      state.sessionTtl = action.payload.sessionTtl;
    },
    setSessionError(state, action) {
      state.errorCode = action.payload.errorCode;
      state.errorMessage = action.payload.errorMessage;
    },
    setSessionStatus(state, action) {
      state.sessionStatus = action.payload.sessionStatus;
    },
    setSessionToken(state, action) {
      state.sessionToken = action.payload.sessionToken;
    },
    setSessionTtl(state, action) {
      state.sessionTtl = action.payload.sessionTtl;
    },
  },
});

export const sessionSliceActions = sessionSlice.actions;

export default sessionSlice;
