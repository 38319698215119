/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */

/* For api status, used by createAsyncThunk */
export const HTTP_STATUS = Object.freeze({
  PENDING: "PENDING",
  FULFILLED: "FULFILLED",
  REJECTED: "REJECTED",
});

/* For webrtc status */
export const WEBRTC_STATUS = Object.freeze({
  UNINITIALIZED: "UNINITIALIZED",
  ERROR: "ERROR",
  CONNECTED: "CONNECTED",
  PENDING: "PENDING",
});

/* For session status */
export const SESSION_STATUS = Object.freeze({
  UNINITIALIZED: "UNINITIALIZED",
  INVALID: "INVALID",
  VALID: "VALID",
  PENDING: "PENDING",
});

/**
 * Config is defined in this way because the object process does not exist inside
 * the browser environment, it’s Node-specific. CRA by default doesn’t do server-side
 * rendering. It can’t inject environment variables during content serving.
 * During transpiling, Webpack process replaces all occurrences of process.env with a
 * string value that was given. This means it can only be configured during build time.
 */
const dummy_device_id = "qwertyuiop";
let vst_endpoint = "https://gnext2-api.q-appliedai.com:443";
let ui_server_endpoint = "https://gnext2-api.q-appliedai.com:443";
let websocket_endpoint = "wss://gnext2-api.q-appliedai.com:443/ws";
// let vst_endpoint = "https://azure-tokkio-api.quantiphi.com:443";
// let ui_server_endpoint = "https://azure-tokkio-api.quantiphi.com:443";
// let websocket_endpoint = "wss://azure-tokkio-api.quantiphi.com:443/ws";
let auth_server = "https://stg.login.nvidia.com";
let enable_ingress = true;
let enable_countdown = false;
let countdown_value = 90;

/* Prefer ENV values if defined, to be used with docker */
if (typeof VST_ENDPOINT !== "undefined") {
  vst_endpoint = VST_ENDPOINT;
}
if (typeof UI_SERVER_ENDPOINT !== "undefined") {
  ui_server_endpoint = UI_SERVER_ENDPOINT;
}
if (typeof WEBSOCKET_ENPOINT !== "undefined") {
  websocket_endpoint = WEBSOCKET_ENPOINT;
}
if (typeof AUTH_SERVER !== "undefined") {
  auth_server = AUTH_SERVER;
}
if (typeof DISABLE_INGRESS !== "undefined") {
  enable_ingress = false;
}
if (typeof DISABLE_COUNTDOWN !== "undefined") {
  enable_countdown = false;
}
if (typeof COUNTDOWN_VALUE !== "undefined") {
  countdown_value = parseInt(COUNTDOWN_VALUE, 10);
}

/* export config */
const config = {
  enableChatWidget: false,
  enableAutoMicMute: false,
  enableLogs: true,
  enableCamera: true,
  enableRefreshOnWaiting: false,
  vstEndpoint: vst_endpoint,
  uiServerEndpoint: ui_server_endpoint,
  websocketEndpoint: websocket_endpoint,
  authServer: auth_server,
  enableEngress: enable_ingress,
  enableCountdown: enable_countdown,
  countdownValue: countdown_value,
  useVstApiDirectly: !enable_ingress,
  sessionTokenRefreshInterval: 15000,
  accessAndIdTokenRefreshInterval: 20000,
  websocketReconnectInterval: 500,
  launchMSTeamsActionEndpoint:
    "https://teams.microsoft.com/l/app/d2c6f111-ffad-42a0-b65e-ee00425598aa?source=app-details-dialog",
  launchFOWActionEndpoint:
    "https://att.sharepoint.com/sites/futureofwork/SitePages/ATT_MyWorkspace.aspx",
};

export default config;
