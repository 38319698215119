import { createSlice } from "@reduxjs/toolkit";

const ccInitialState = {
    status: true
};

/* Redux slice to manage captions enabled / disabled state */
const ccSlice = createSlice({
    name: "cc",
    initialState: ccInitialState,
    reducers: {
        toggleCaptions(state) {
            state.status = !state.status;
        },
        enableCaption(state) {
            state.status = true
        },
        disableCaption(state) {
            state.status = false
        }
    },
});

export const ccStatusActions = ccSlice.actions;

export default ccSlice;
