/* eslint-disable no-console */

export function isPortraitMode() {
  const isPortrait = window.innerWidth / window.innerHeight < 1;
  return isPortrait;
}

export function getContentHeight() {
  const videoContainerHeight =
    document.getElementById("video-container").offsetHeight;
  const NewMenuHeight = isPortraitMode()
    ? `calc(100vh - 49px - 25px - ${videoContainerHeight}px - 32px - 16px - 125px + 70px )`
    : `calc(1600px - 49px - 25px - ${videoContainerHeight}px - 32px - 16px - 125px - 5px )`;
  console.log("menu height", NewMenuHeight);
  return NewMenuHeight;
}

export function getSessionCookie(session) {
  localStorage.setItem(`SessionCookie`, session);
}

export function setSessionCookie() {
  localStorage.getItem(`SessionCookie`);
}

export function preProcessASR(string) {
  const replacements = {
    "Two Thousand and Twenty Three" : "2023",
    "Two thousand and twenty three" : "2023",
    "two thousand and twenty three" : "2023",
    "Two Thousand Twenty Three" : "2023",
    "Two thousand twenty three" : "2023",
    "two thousand twenty three" : "2023",
    "Twenty Twenty Three" : "2023",
    "Twenty twenty three" : "2023",
    "twenty twenty three" : "2023",
    "Twenty Three" : "23",
    "Twenty three" : "23",
    "twenty Three" : "23",
    "twenty three" : "23",
    "Google next" : "Google Next",
    "google next" : "Google Next",
    "quantify" : "Quantiphi",
    "Quantify" : "Quantiphi",
    "google": "Google",
    "Twenty-Twenty-Three" : "2023",
    "Twenty-twenty-three" : "2023",
    "twenty-twenty-three" : "2023",
    "Twenty-Three" : "23",
    "Twenty-three" : "23",
    "twenty-Three" : "23",
    "twenty-three" : "23"
  }
  if (Object.keys(replacements).length === 0) {
    return string;
  }
  let newString = string;
  Object.keys(replacements).forEach(key => {
    newString = newString.replaceAll(key, replacements[key])
  });
  return newString;
}

export function preProcessTTS(string) {
  const replacements = {
    "twelfth" : "12th",
    "thirteenth" : "13th",
    "twenty twenty three" : "2023",
    "versus" : "vs",
    "zero one" : "(0-1)",
    "zero two" : "(0-2)",
    "three zero" : "(3-0)",
    "two zero" : "(2-0)",
    "twentieth" : "20th",
    "Baayern" : "Bayern",
    "One Day International" : "One Day International (ODI)",
    "O D I" : "ODI",
    "O D eyes" : "ODIs",
    "Ishaan" : "Ishan",
    "Shubhmaan" : "Shubhman",
    "twenty fourteen" : "2014",
    "twenty thirteen" : "2013",
    "twenty seventeen" : "2017",
    "twenty twenty two" : "2022",
    "A-I" : "AI"
  }
  if (Object.keys(replacements).length === 0) {
    return string;
  }
  let newString = string;
  Object.keys(replacements).forEach(key => {
    newString = newString.replaceAll(key, replacements[key])
  });
  return newString;
}