/* eslint-disable no-prototype-builtins */
import { createSlice } from "@reduxjs/toolkit";

const ipInitialState = {
    ipAddress: "xx.xx.xx.xx"
};

/* Redux slice to save client IP address */
const ipAddressSlice = createSlice({
    name: "ip",
    initialState: ipInitialState,
    reducers: {
        updateIPAddress(state, action) {
            state.ipAddress = action.payload
        }
    },
});

export const ipAddressSliceActions = ipAddressSlice.actions;

export default ipAddressSlice;
