import { configureStore } from "@reduxjs/toolkit";
import webSocketSlice from "../connection/websocket/websocketSlice";
import asrSlice from "../features/asrText/asrSlice";
import ovSlice from "../features/bot-state/ovSlice";
import ttsSlice from "../features/ttsText/ttsSlice";
import webrtcSlice from "../connection/webrtc/webrtcSlice";
import postRoiEntrySlice from "../features/manage-roi/roiEntry";
import authorizationCodeSlice from "../session/AuthorizationCodeWrapper/authorizationCodeSlice";
import accessAndIdTokenSlice from "../session/AccessAndIdTokenWrapper/AccessAndIdTokenSlice";
import sessionSlice from "../session/sessionSlice";
import ccSlice from "../features/captions/CaptionsToggleSlice";
import chatboxSlice from "../features/chatbox/chatboxSlice";
import ipAddressSlice from "../features/ip-address/ipAddressSlice";
import feedbackSlice from "../features/feedback/FeedbackSlice";
import micEnabledSlice from "../features/top-bar/micEnabledSlice";

/* Redux Store */
const store = configureStore({
  reducer: {
    uuid: webSocketSlice.reducer,
    asrState: asrSlice.reducer,
    ovState: ovSlice.reducer,
    ttsState: ttsSlice.reducer,
    session: sessionSlice.reducer,
    roiEntryStatus: postRoiEntrySlice.reducer,
    webrtcStatus: webrtcSlice.reducer,
    authorizationCodeState: authorizationCodeSlice.reducer,
    accessAndIdTokenState: accessAndIdTokenSlice.reducer,
    ccState: ccSlice.reducer,
    chatboxSlice: chatboxSlice.reducer,
    ipAddress: ipAddressSlice.reducer,
    feedback: feedbackSlice.reducer,
    micEnabledState: micEnabledSlice.reducer
  },
});

export default store;
