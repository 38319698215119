/* eslint-disable no-console */
import React from "react";
import AccessAndIdTokenWrapper from "../AccessAndIdTokenWrapper/AccessAndIdTokenWrapper";
import useQuery from "../../utils/useQuery";
import interceptAndRetry from "../../connection/axios-interceptors/interceptAndRetry";

/**
 * This is a one time process to get one time usable authorization code
 * from the URL. After getting the authorization code attempt to get
 * access token and id token
 */
export default function AuthorizationCodeWrapper() {
  const query = useQuery();
  interceptAndRetry();
  const authorizationCode = query.get("code");
  if (authorizationCode != null) {
    /**
     * Since authorization code is time use we dont really need to store it
     * redux store and we can simply pass it as props. Doing it just for consistency purpose.
     */
    console.debug("AUTH CODE: ", authorizationCode);
    window.isAuthorzationCode = true;
  }
  else {
    window.isAuthorzationCode = false;
  }

  return (
    <div>
      <AccessAndIdTokenWrapper authorizationCode={authorizationCode} />
    </div>
  );
}
