import { styled } from '@mui/material/styles';

/* Util functions that define styles used in the UI */

const drawerWidth = 400;

const fabStyle = {
    margin: 0,
    top: 'auto',
    right: 30,
    bottom: 30,
    left: 'auto',
    position: 'fixed',
    background: 'transparent'
};

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

const Main = styled('main', {
    shouldForwardProp: (prop) => prop !== 'open'
})(
    ({
        theme,
        open
    }) => ({
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginRight: drawerWidth,
        }),
    }),
);

const DrawerHeader = styled('div')(({
    theme
}) => ({
    display: 'fixed',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    height: 70.38,
    ...theme.mixins.toolbar,
}));

/**
 * TODO: When landscape design is ready remove the below non-standard method and switch
 * the UI between landscape and portrait design based on screen type
 */
const handleWindowSizeChange = () => {
    const body = document.getElementsByTagName("BODY")[0];
    const html = document.getElementsByTagName("HTML")[0];
    body.style.maxWidth = "100%";
    html.style.maxWidth = "100%";
};

export {
    drawerWidth,
    fabStyle,
    modalStyle,
    Main,
    DrawerHeader,
    handleWindowSizeChange
}