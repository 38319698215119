/* eslint-disable no-prototype-builtins */
import { createSlice } from "@reduxjs/toolkit";

const authorizationCodeInitialState = {
  authorizationCode: null,
};

const authorizationCodeSlice = createSlice({
  name: "authorizationCode",
  initialState: authorizationCodeInitialState,
  reducers: {
    setAuthorizationCode(state, action) {
      state.authorizationCode = action.payload;
    },
  },
});

export const authorizationCodeSliceActions = authorizationCodeSlice.actions;

export default authorizationCodeSlice;
