/* eslint-disable no-prototype-builtins */
import { createSlice } from "@reduxjs/toolkit";

const AccessAndIdTokenInitialState = {
  accessToken: null,
  idToken: null,
};

const accessAndIdTokenSlice = createSlice({
  name: "AccessAndIdToken",
  initialState: AccessAndIdTokenInitialState,
  reducers: {
    setAccessAndIdToken(state, action) {
      state.accessToken = action.payload.accessToken;
      state.idToken = action.payload.idToken;
    },
  },
});

export const accessAndIdTokenSliceActions = accessAndIdTokenSlice.actions;

export default accessAndIdTokenSlice;
