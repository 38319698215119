/* eslint-disable no-prototype-builtins */
import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";

const chatboxInitialState = {
  chats: []
};

/* Redux slice to manage chat history */
const chatboxSlice = createSlice({
  name: "chats",
  initialState: chatboxInitialState,
  reducers: {
    addNewChat(state, action) {
      if (action.payload.chat !== "") {
        state.chats.push({
          uuid: uuidv4(),
          chat: action.payload.chat.trim(),
          time: new Date().toLocaleTimeString(),
          speaker: action.payload.speaker,
          feedback: false
        });
      }
    },
    clearAllChats(state) {
      state.chats = []
    },
    markFeedbackSubmitted(state, action) {
      const newState = state.chats.map(chat => {
        if (chat.uuid === action.payload.uid) {
          return {...chat, feedback: true};
        }
        return chat;
      });
      state.chats = newState;
    }
  },
});

export const chatbotSliceActions = chatboxSlice.actions;

export default chatboxSlice;
