/* eslint-disable no-param-reassign */
/* eslint-disable no-console */
import axios from "axios";
import uiConfig from "../../config/config";

export default function interceptAndAppendHeaders() {
  axios.interceptors.request.use(
    (config) => {
      if (uiConfig.enableEngress) {
      /**
       * whether or not cross-site Access-Control requests should be made using credentials such as cookies, 
       * authorization headers or TLS client certificates.
       */
        config.withCredentials = true;
      }
      // Add Authorization header individually wherever required because its not required for all API calls
      return config;
    },
    (error) => Promise.reject(error)
  );
}
