/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import { useState, useEffect } from "react";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { webSocketActions } from "./websocketSlice";
import config from "../../config/config";
import { ovSliceActions } from "../../features/bot-state/ovSlice";
import { asrSliceActions } from "../../features/asrText/asrSlice";
import { ttsSliceActions } from "../../features/ttsText/ttsSlice";
import { chatbotSliceActions } from "../../features/chatbox/chatboxSlice";
import { micEnabledSliceActions } from "../../features/top-bar/micEnabledSlice";
import findAndExecuteAction from "../../utils/actions";

const websocketErrorToString = (event) => {
  let reason = "";
  if (event.code === 1000)
  reason = "Normal closure, meaning that the purpose for which the connection was established has been fulfilled.";
  else if(event.code === 1001)
    reason = "An endpoint is \"going away\", such as a server going down or a browser having navigated away from a page.";
  else if(event.code === 1002)
    reason = "An endpoint is terminating the connection due to a protocol error";
  else if(event.code === 1003)
    reason = "An endpoint is terminating the connection because it has received a type of data it cannot accept (e.g., an endpoint that understands only text data MAY send this if it receives a binary message).";
  else if(event.code === 1004)
    reason = "Reserved. The specific meaning might be defined in the future.";
  else if(event.code === 1005)
    reason = "No status code was actually present.";
  else if(event.code === 1006)
  reason = "The connection was closed abnormally, e.g., without sending or receiving a Close control frame";
  else if(event.code === 1007)
    reason = "An endpoint is terminating the connection because it has received data within a message that was not consistent with the type of the message (e.g., non-UTF-8 [https://www.rfc-editor.org/rfc/rfc3629] data within a text message).";
  else if(event.code === 1008)
    reason = "An endpoint is terminating the connection because it has received a message that \"violates its policy\". This reason is given either if there is no other sutible reason, or if there is a need to hide specific details about the policy.";
  else if(event.code === 1009)
  reason = "An endpoint is terminating the connection because it has received a message that is too big for it to process.";
  else if(event.code === 1010) // Note that this status code is not used by the server, because it can fail the WebSocket handshake instead.
    reason = `An endpoint (client) is terminating the connection because it has expected the server to negotiate one or more extension, but the server didn't return them in the response message of the WebSocket handshake. <br /> Specifically, the extensions that are needed are: ${  event.reason}`;
  else if(event.code === 1011)
    reason = "A server is terminating the connection because it encountered an unexpected condition that prevented it from fulfilling the request.";
  else if(event.code === 1015)
    reason = "The connection was closed due to a failure to perform a TLS handshake (e.g., the server certificate can't be verified).";
  else
    reason = "Unknown reason";
  return reason;
}

function WebSocketConnection() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [socketUrl, setSocketUrl] = useState(`${config.websocketEndpoint}`);
  
  /* Initiate websocket connection */
  const streamId = useSelector((state) => state.uuid.streamId);
  const sessionActive = useSelector((state) => state.uuid.sessionActive);
  
  useEffect(() => {
    if(streamId !== "") {
      setSocketUrl(`${config.websocketEndpoint}?stream_id=${streamId.toString()}`);
    }
  }, [streamId]);

  const { lastJsonMessage, readyState } = useWebSocket(
    socketUrl,
    {
      onError: (e) => console.error("websocket error occured", e),
      onClose: (e) => console.error(websocketErrorToString(e)),
      share: true,
      retryOnError: true,
      reconnectAttempts: Infinity,
      reconnectInterval: 1000,
      shouldReconnect: () => true,
      onReconnectStop: () => {
        console.log("Max attempt reached");
        dispatch(webSocketActions.setWebsocket({ uuid: "", status: 3 }));
      },
    }
  );


  useEffect(() => {
    if(!sessionActive) {
        if(location.pathname !== `/welcome`) {
        navigate(`/welcome`);
      }
    }
    
  }, [sessionActive]);
    
  useEffect(() => {
    dispatch(webSocketActions.setWebsocket({ uuid: "", status: readyState }));
  }, [readyState]);

  useEffect(() => {
    console.log("received a new message from WS: ", lastJsonMessage)
    const actionHandler = (message) => {
      
      // fovExit Action
      if (message.action === "fovExit") {
        dispatch(webSocketActions.setSessionActive(false));
      }
      // update TTS state
      if (Object.prototype.hasOwnProperty.call(message, "tts")) {
        // dispatch(ttsSliceActions.setTtsState(message.tts));
        // dispatch(chatbotSliceActions.addNewChat({speaker: "tts", chat: message.tts}));
        const tts = findAndExecuteAction(message.tts)
        dispatch(ttsSliceActions.setTtsState(tts));
        dispatch(chatbotSliceActions.addNewChat({speaker: "tts", chat: tts}));
      }
      // update ASR state
      if (Object.prototype.hasOwnProperty.call(message, "asr")) {
        dispatch(asrSliceActions.setAsrState(message.asr));
        dispatch(chatbotSliceActions.addNewChat({speaker: "asr", chat: message.asr}));
      }
      // disable mic button on SPEAKING and THINKING OV state
      if (config.enableAutoMicMute) {
        if (Object.prototype.hasOwnProperty.call(message, "ov_state")) {
          if (["SPEAKING", "THINKING"].includes(message.ov_state.toUpperCase())) {
            dispatch(micEnabledSliceActions.disableMic());
            dispatch(micEnabledSliceActions.disableMicButton());
          } else {
            dispatch(micEnabledSliceActions.enableMic());
            dispatch(micEnabledSliceActions.enableMicButton());
          }
        }
      }

      // disable mic button on SPEAKING and THINKING OV state
      if (config.enableRefreshOnWaiting) {
        if (Object.prototype.hasOwnProperty.call(message, "ov_state")) {
          if (["WAITING"].includes(message.ov_state.toUpperCase())) {
            window.location.reload(false);
          }
        }
      }

      // update OV state
      if (Object.prototype.hasOwnProperty.call(message, "ov_state")) {
        if (message.ov_state.toUpperCase() === "WAIT_FOR_TRIGGER") {
          dispatch(ovSliceActions.setOvState("WAITING"));
        } else if (message.ov_state.toUpperCase() === "ASR_ACTIVE") {
          dispatch(ovSliceActions.setOvState("LISTENING"));
        } else if (message.ov_state.toUpperCase() === "TTS_ACTIVE") {
          dispatch(ovSliceActions.setOvState("SPEAKING"));
        } else {
          dispatch(ovSliceActions.setOvState(message.ov_state));
        }
      }
    };

    if (lastJsonMessage !== null) {
      // console.debug("lastJsonMessage: ", lastJsonMessage);
      /* Set UUID if present. Otherwise set items to display */
      if (Object.prototype.hasOwnProperty.call(lastJsonMessage, "client_id")) {
        const clientId = lastJsonMessage.client_id;
        // console.info("client id: ", clientId);
        dispatch(
          webSocketActions.setWebsocket({ uuid: clientId, status: readyState })
        );
        /* delete cart from previous connection since multi client is not supported yet */
      } else if (
        Object.prototype.hasOwnProperty.call(lastJsonMessage, "action") ||
        Object.prototype.hasOwnProperty.call(lastJsonMessage, "tts") ||
        Object.prototype.hasOwnProperty.call(lastJsonMessage, "asr") ||
        Object.prototype.hasOwnProperty.call(lastJsonMessage, "ov_state")
      ) {
        actionHandler(lastJsonMessage);
      } else {
        dispatch(
          webSocketActions.setItems({
            data: lastJsonMessage,
            status: readyState,
          })
        );
        dispatch(webSocketActions.setSessionActive(true));
      }
    }
  }, [lastJsonMessage]);
}

export default WebSocketConnection;
