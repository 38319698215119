import { createTheme } from "@mui/material/styles";
import darkScrollbar from "@mui/material/darkScrollbar";

/* Base theme for the UI, app level changes can be made here */
const tokkioTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      // main: "#00A8E0",
      main: "#20B283",
      // main: "#ED1A3D",
      contrastText: "rgba(255,255,255,0.87)",
    },
    secondary: {
      main: "#616161",
      light: "#FFFFFF"
    },
    light: {
      main: "#FFFFFF"
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: darkScrollbar(),
      },
    },
  },
  typography: {
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    fontSize: 12,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
});

export default tokkioTheme;
