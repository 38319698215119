/* eslint-disable no-prototype-builtins */
import { createSlice } from "@reduxjs/toolkit";

const ttsInitialState = {
  tts: ""
};

/* Redux slice to manage TTS state */
const ttsSlice = createSlice({
  name: "tts",
  initialState: ttsInitialState,
  reducers: {
    setTtsState(state, action) {
      state.tts = action.payload;
    },
    clearTtsstate(state) {
      state.tts = ""
    }
  },
});

export const ttsSliceActions = ttsSlice.actions;

export default ttsSlice;
