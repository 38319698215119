import { feedbackSliceActions } from "../features/feedback/FeedbackSlice";
import config from "../config/config";
import store from "../store/store";

/* Handles Action */
const findAndExecuteAction = str => {
    const state = store.getState()
    const action = str.split("|")
    if (action.length === 2) {
        switch (action[1].trim()) {
            case "launchTeams":
                console.log("Opening Teams");
                window.open(config.launchMSTeamsActionEndpoint, '_blank', 'noopener,noreferrer')
                break;
            case "launchBrowser":
                console.log("Opening new tab");
                window.open("", '_blank', 'noopener,noreferrer')
                break;
            case "launchFOW":
                console.log("Opening FOW website");
                window.open(config.launchFOWActionEndpoint, '_blank', 'noopener,noreferrer')
                break;
            case "IPaddress":
                console.log("Getting external IP address")
                action[0] = `${action[0]} ${state.ipAddress.ipAddress}`
                break;
            case "feedback":
                console.log("Opening feedback dialog.");
                store.dispatch(feedbackSliceActions.enableFeedback())
                break
            default:
                console.log("No actions found.")
                break;
        }
    }
    return action[0];
}

export default findAndExecuteAction;