/* eslint-disable no-console */
import React from "react";
import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux";

export default function CountdownTimer() {
  const sessionTime = useSelector((state) => state.session.sessionTime);
  const [counter, setCounter] = React.useState(sessionTime);
  React.useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    if (counter === 0) {
      console.debug("Countdown finished !!!");
      setCounter("SESSION EXPIRED");
    }
    return () => clearInterval(timer);
  }, [counter]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        align: "center",
      }}
    >
      <Typography
        variant='h7'
        color='primary.main'
        sx={{
          display: { xs: "none", md: "flex", color: "white" },
        }}
      >
        TIME REMAINING:{" "}
        <Typography
          variant='h7'
          color='primary.main'
          sx={{
            display: {
              xs: "none",
              md: "flex",
              color:
                counter <= 15 || counter === "SESSION EXPIRED"
                  ? "red"
                  : "white",
              marginLeft: 2,
              fontWeight: "bold",
            },
          }}
        >
          {counter}
        </Typography>
      </Typography>
    </div>
  );
}
