/* eslint-disable no-prototype-builtins */
import { createSlice } from "@reduxjs/toolkit";
import { WEBRTC_STATUS } from "../../config/config";

const webrtcInitialState = {
  webrtcSenderStatus: WEBRTC_STATUS.UNINITIALIZED,
  webrtcReceiverStatus: WEBRTC_STATUS.UNINITIALIZED,
  webrtcNetworkQuality: "high" // high or low
};

const webrtcSlice = createSlice({
  name: "webrtcStatus",
  initialState: webrtcInitialState,
  reducers: {
    setWebrtcSenderStatus(state, action) {
      state.webrtcSenderStatus = action.payload;
    },
    setWebrtcReceiverStatus(state, action) {
      state.webrtcReceiverStatus = action.payload;
    },
    setWebrtcNetworkQuality(state, action) {
      state.webrtcNetworkQuality = action.payload;
    },
  },
});

export const webrtcActions = webrtcSlice.actions;

export default webrtcSlice;
