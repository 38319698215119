/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable no-console */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, useRef, useState } from "react";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { useSnackbar } from "notistack";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import { useSelector, useDispatch } from "react-redux";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import config, { WEBRTC_STATUS } from "../../../config/config";
import CCState from "../../../features/captions/CCState";

import { webrtcActions } from "../webrtcSlice";
import { ipAddressSliceActions } from "../../../features/ip-address/ipAddressSlice";

const uiServerOrVstEndpointString = config.useVstApiDirectly ? `${config.vstEndpoint}/api` : `${config.uiServerEndpoint}/vms`;

const PEER_CONNECTION_OPTIONS = { optional: [{ DtlsSrtpKeyAgreement: true }] };
const OFFER_OPTIONS = { offerToReceiveAudio: true, offerToReceiveVideo: true };
const MAX_RETRIES = 3;

function Webrtc(props) {
  const dispatch = useDispatch();
  const avatarVideoRef = useRef();
  const deviceId = useRef();
  const earlyCandidates = useRef([]);
  const pc = useRef();
  const { enqueueSnackbar } = useSnackbar();
  const peerId = useRef();
  const restartCount = useRef(0);
  // used in test to detect if webrtc is receiving frames
  const webrtcStatsRef = useRef();
  const [isVideoPlaying, setPlayingStatus] = useState("avatar-not-playing");
  const [open, setOpen] = React.useState(false);
  const webrtcReceiverStatus = useSelector(
    (state) => state.webrtcStatus.webrtcReceiverStatus
  );
  const ccState = useSelector((state) => state.ccState.status);
  const sessionTime = useSelector((state) => state.session.sessionTime);
  const [counter, setCounter] = React.useState(sessionTime);

  const videoPlayerStyles = {
    position: "relative",
    padding: 20,
    objectFit: "contain",
    height: "750px",
    background:
      "radial-gradient(53.31% 53.31% at 49.95% 100%, #000000 0%, rgba(0, 0, 0, 0) 100%)",
  };

    /* Starts WebRtc connection */
  useEffect(() => {
    startWebrtcConnection();
    return () => {
      stopWebrtcConnection();
    };
  }, []);

    /* Stops WebRtc connection */
  useEffect(() => {
    let timer;
    console.log("counter: ", counter);
    if (config.enableEngress) {
      timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
      if (counter === 2) {
        console.debug(
          "Terminating peer connection in 9 seconds after countdown expires"
        );
        setTimeout(() => {
          // eslint-disable-next-line no-use-before-define
          stopWebrtcConnection();
        }, 9000);
      }
    }
    return () => clearInterval(timer);
  }, [counter]);

  const openPermissionDialog = () => {
    setOpen(true);
  };

  const closePermissionDialog = (event, reason) => {
    if (reason == null) setOpen(false);
    else if (reason !== "escapeKeyDown" && reason !== "backdropClick") {
      setOpen(false);
    }
  };

  const enableAudio = () => {
    if (avatarVideoRef.current) {
      console.debug("WEBRTC RECEIVER: enabling audio...");
      avatarVideoRef.current.muted = false;
    }
  };

  const addIceCandidate = (candidate) => {
    const jsonData = {
      peerid: pc.current.peerId.toString(),
      candidate,
    };
    console.debug(
      "WEBRTC RECEIVER: calling vms/addIceCandidate with ",
      jsonData
    );
    const promise = axios(
      `${uiServerOrVstEndpointString}/addIceCandidate`,
      {
        method: "post",
        data: jsonData,
      }
    );
    promise.then((response) => {
      console.log("added ice candidate ?", response.data);
    });
    promise.catch((e) => {
      console.error("WEBRTC RECEIVER: add ice candidate error ", e);
    });

    return () => {
      clearInterval(webrtcStatsRef.current);
    };
  };

  const onReceiveCandidate = (response) => {
    const candidates = response.data;
    console.debug(
      `Received candidates from VMS: ${JSON.stringify(candidates)}`
    );
    if (candidates) {
      console.debug(
        "WEBRTC RECEIVER: Creating RTCIceCandidate from each received candidate.."
      );
      for (let i = 0; i < candidates.length; i += 1) {
        const candidate = new RTCIceCandidate(candidates[i]);

        console.debug(
          `Adding ICE candidate - ${i} :${JSON.stringify(candidate)}`
        );
        pc.current.addIceCandidate(
          candidate,
          () => {
            console.debug(`addIceCandidate OK - ${i}`);
          },
          (error) => {
            console.debug(`addIceCandidate error - ${i}`, error);
          }
        );
      }
    }
  };

  const getIceCandidate = () => {
    console.debug("WEBRTC RECEIVER: calling api/getIceCandidate");
    axios
      .get(
        `${uiServerOrVstEndpointString}/getIceCandidate?peerid=${pc.current.peerId}`
      )
      .then(onReceiveCandidate)
      .catch((e) => {
        console.error("WEBRTC RECEIVER: getIceCandidate error", e);
      });
  };

  const setRemoteDescription = (sessionDescriptionAnswer) => {
    pc.current
      .setRemoteDescription(new RTCSessionDescription(sessionDescriptionAnswer))
      .then(() => {
        earlyCandidates.current.forEach(addIceCandidate);
        getIceCandidate();
      })
      .catch((e) => {
        console.error("WEBRTC RECEIVER: onReceiveCall: ", e);
      });
  };

  const sendSessionDescriptionToVst = (sessionDescription) => {
    const sessionDescriptionPayload = {
      peerid: pc.current.peerId.toString(),
      options: {
        quality: "auto",
        rtptransport: "udp",
        timeout: 60,
        streamId: deviceId.current,
      },
      sensorId: deviceId.current,
      sessionDescription,
    };
    console.debug(
      "WEBRTC RECEIVER: Payload of stream start: ",
      sessionDescriptionPayload
    );

    const promise = axios(
      `${uiServerOrVstEndpointString}/stream/start`,
      {
        method: "post",
        data: sessionDescriptionPayload,
      }
    );
    promise.then((response) => {
      const sessionDescriptionAnswer = response.data;
      console.debug(
        "Received response of stream start: ",
        sessionDescriptionAnswer
      );
      console.debug(
        "WEBRTC RECEIVER: Signaling state is ",
        pc.current.signalingState
      );
      if (pc.current.signalingState === "have-local-offer") {
        console.debug("WEBRTC RECEIVER: OK signalingState");
      } else {
        console.debug("WEBRTC RECEIVER: unexpected signalingState");
      }
      setRemoteDescription(sessionDescriptionAnswer);
    });
    promise.catch((e) => {
      console.error("WEBRTC RECEIVER: wertc error: ", e);
      dispatch(webrtcActions.setWebrtcReceiverStatus(WEBRTC_STATUS.ERROR));
      // eslint-disable-next-line no-use-before-define
      restartWebRtcConnection();
    });
  };

  const createOffer = () => {
    pc.current
      .createOffer(OFFER_OPTIONS)
      .then((sessionDescription) => {
        console.debug(
          "WEBRTC RECEIVER: session Description",
          sessionDescription
        );
        pc.current.setLocalDescription(sessionDescription);
        return sessionDescription;
      })
      .then((sessionDescription) => {
        sendSessionDescriptionToVst(sessionDescription);
      })
      .catch((error) => {
        console.error("WEBRTC RECEIVER: Failed to create offer", error);
      });
  };

  const webrtcStatsHelper = () => {
    webrtcStatsRef.current = setInterval(() => {
      try {
        pc.current.getStats(null).then((stats) => {
          stats.forEach((report) => {
            if (report.type === "inbound-rtp" && report.kind === "video") {
              if (
                Object.prototype.hasOwnProperty.call(report, "framesPerSecond")
              ) {
                if (report.framesPerSecond > 0) {
                  clearInterval(webrtcStatsRef.current);
                  if (config.enableLogs)
                    console.info(
                      "WEBRTC RECEIVER: Webrtc client is receiving frames"
                    );
                }
              }
            }
          });
        });
      } catch (error) {
        console.error("WEBRTC RECEIVER: Webrtc error", error);
      }
    }, 2000);
  };

  const playVideoAndTestFps = () => {
    setPlayingStatus("avatar-playing");
    /* Try to unmute video */
    avatarVideoRef.current.muted = false;
    const playPromise = avatarVideoRef.current.play();
    if (playPromise !== undefined) {
      playPromise
        .then(() => {
          if (config.enableLogs)
            console.info("WEBRTC RECEIVER: Autoplay with audio successful");
        })
        .catch((error) => {
          console.error("WEBRTC RECEIVER: Autoplay with audio failed", error);
          avatarVideoRef.current.muted = true;
          avatarVideoRef.current.play();
          openPermissionDialog();
        });
    }
    webrtcStatsHelper();
  };

  const onConnectionStateChange = () => {
    // FireFox does not support onConnectionStateChange event
    console.info(
      "WEBRTC RECEIVER: connection state change: ",
      pc.current.connectionState
    );
    if (pc.current.connectionState === "disconnected") {
      console.debug("WEBRTC RECEIVER: Lost peer connection...");
    }
  };

  const onSignalingStateChange = () => {
    if (config.enableLogs)
      console.info(
        "WEBRTC RECEIVER: signaling state change: ",
        pc.current.signalingState
      );
  };

  const onIceGatheringStateChange = () => {
    if (config.enableLogs)
      console.info(
        "WEBRTC RECEIVER: gathering state change: ",
        pc.current.iceGatheringState
      );
  };

  const onIceCandidateError = (e) => {
    console.debug("WEBRTC RECEIVER: onIceCandidateError: ", e);
    if (e.errorCode !== 701) {
      console.error(
        `${e.errorText} error code ${e.errorCode} and url ${e.url}`,
        "onIceCandidateError"
      );
    }
    if (e.errorCode === 701) {
      console.debug(
        "error code is 701 that means DNS failed for ipv6, harmless error"
      );
    }
  };

  const onIceConnectionStateChange = () => {
    if (config.enableLogs)
      console.info(
        "WEBRTC RECEIVER: ice connection state change: ",
        pc.current.iceConnectionState
      );
    if (pc.current && pc.current.iceConnectionState === "new") {
      getIceCandidate();
    }
    if (pc.current.iceConnectionState === "connected") {
      dispatch(webrtcActions.setWebrtcReceiverStatus(WEBRTC_STATUS.CONNECTED));
      restartCount.current = 0;
      playVideoAndTestFps();
    }
    if (pc.current.iceConnectionState === "disconnected") {
      setPlayingStatus("avatar-not-playing");
      dispatch(webrtcActions.setWebrtcReceiverStatus(WEBRTC_STATUS.ERROR));
    }
    if (pc.current.iceConnectionState === "failed") {
      setPlayingStatus("avatar-not-playing");
      dispatch(webrtcActions.setWebrtcReceiverStatus(WEBRTC_STATUS.ERROR));
      // eslint-disable-next-line no-use-before-define
      restartWebRtcConnection();
    }
  };

  const onTrack = (event) => {
    const [stream] = event.streams;
    avatarVideoRef.current.srcObject = stream;
  };

  const onIceCandidate = (event) => {
    if (!event.candidate) {
      console.debug(
        "received null candidate, that means its the last candidate"
      );
      return;
    }
    if (event.candidate) {
      if (event.candidate.candidate.length === 0) {
        console.debug(
          "Recevived empty string for candidate - client is firefox"
        );
        return;
      }
      console.debug(
        "received candidate inside onIceCandidate callback: ",
        event.candidate.candidate
      );
      // If a srflx candidate was found, notify that the STUN server works!
      if (event.candidate.type === "srflx") {
        console.debug(
          "WEBRTC RECEIVER: The STUN server is reachable for this candidate!"
        );
        console.debug(`Public IP Address is: ${event.candidate.address}`);
        dispatch(
          ipAddressSliceActions.updateIPAddress(
            event.candidate.address
          )
        );
      }
      // If a relay candidate was found, notify that the TURN server works!
      if (event.candidate.type === "relay") {
        console.debug(
          "WEBRTC RECEIVER: The TURN server is reachable for this candidate!"
        );
      }
      if (pc.current && pc.current.currentRemoteDescription) {
        addIceCandidate(event.candidate);
      } else {
        earlyCandidates.current.push(event.candidate);
      }
    }
  };

  const createRTCPeerConnection = (iceServers) => {
    if (config.enableLogs)
      console.debug("WEBRTC RECEIVER: ICE Servers: ", iceServers);
    try {
      const peerConnection = new RTCPeerConnection(
        { iceServers },
        PEER_CONNECTION_OPTIONS
      );
      peerConnection.peerId = peerId.current;
      peerConnection.onicecandidate = onIceCandidate;
      peerConnection.ontrack = onTrack;
      peerConnection.oniceconnectionstatechange = onIceConnectionStateChange;
      peerConnection.onicecandidateerror = onIceCandidateError;
      peerConnection.onicegatheringstatechange = onIceGatheringStateChange;
      peerConnection.onsignalingstatechange = onSignalingStateChange;
      peerConnection.onconnectionstatechange = onConnectionStateChange;
      pc.current = peerConnection;
    } catch (error) {
      console.error(`Failed to create RTC peer connection.`, error);
      dispatch(webrtcActions.setWebrtcReceiverStatus(WEBRTC_STATUS.ERROR));
    }

    createOffer();
  };

  const onDeviceFetch = () => {
    peerId.current = Math.random();
    axios
      .get(
        `${uiServerOrVstEndpointString}/getIceServers?peerId=${peerId.current}`
      )
      .then((response) => {
        console.log(response.data);
        createRTCPeerConnection(response.data.iceServers);
      })
      .catch((rejectedValueOrSerializedError) => {
        console.error(rejectedValueOrSerializedError);
        dispatch(webrtcActions.setWebrtcReceiverStatus(WEBRTC_STATUS.ERROR));
      });
  };

  const startWebrtcConnection = () => {
    dispatch(webrtcActions.setWebrtcReceiverStatus(WEBRTC_STATUS.PENDING));
    axios
      .get(`${uiServerOrVstEndpointString}/device/list`)
      .then((response) => {
        console.log(response.data);
        console.log("response.headers", response.headers);
        for (let i = 0; i < response.data.length; i += 1) {
          if (response.data[i].name.includes("Tokkio_Avatar")) {
            deviceId.current = response.data[i].id;
            break;
          }
        }
        if (deviceId.current == null) {
          const errorObj = {
            error_description: "unable to find stream with name Tokkio_Avatar",
          };
          throw errorObj;
        }
        onDeviceFetch();
      })
      .catch((rejectedValueOrSerializedError) => {
        console.error(rejectedValueOrSerializedError);
        dispatch(webrtcActions.setWebrtcReceiverStatus(WEBRTC_STATUS.ERROR));
        // eslint-disable-next-line no-use-before-define
        restartWebRtcConnection();
      });
  };

  const stopWebrtcConnection = () => {
    if (pc.current != null) {
      const jsonData = {
        peerid: pc.current.peerId.toString(),
      };
      pc.current.close();
      pc.current = null;
      peerId.current = null;
      earlyCandidates.current = [];
      avatarVideoRef.current.srcObject = null;
      console.debug("WEBRTC RECEIVER: calling api/stream/stop with ", jsonData);
      const promise = axios(
        `${uiServerOrVstEndpointString}/stream/stop`,
        {
          method: "post",
          data: jsonData,
        }
      );
      promise.then((response) => {
        console.log("stream stop ?", response.data);
      });
      promise.catch((e) => {
        console.error("WEBRTC RECEIVER: stream stop error", e);
      });
    }
  };

  const restartWebRtcConnection = () => {
    if (restartCount.current < MAX_RETRIES) {
      // add delay to avoid page hang
      setTimeout(() => {
        stopWebrtcConnection();
        startWebrtcConnection();
        restartCount.current += 1;
      }, 1000);
      console.debug("WEBRTC RECEIVER: RESTARTING WEBRTC CONNECTION !!!");
    } else {
      console.debug(
        "WEBRTC RECEIVER: WEBRTC MAX RETRY LIMIT REACHED, RESTART PAGE !!!"
      );
      enqueueSnackbar("Unable to stream avatar, please refresh page", {
        variant: "error",
      });
    }
  };

  const videoHeight = window.innerHeight > window.innerWidth ? "85vh" : "80vh"

  return (
    <Grid container item xs={12} justifyContent="center">
      <div id='video-container'>
        <div style={{
          position: "relative",
          padding: 20,
          objectFit: "contain",
          height: videoHeight,
          background:
            "radial-gradient(53.31% 53.31% at 49.95% 100%, #000000 0%, rgba(0, 0, 0, 0) 100%)",
          }} id={isVideoPlaying}>
          <video ref={avatarVideoRef} autoPlay controls={false} height="100%" width="auto" muted id='animated-avatar' />
          {ccState &&
          <CCState />
          }
          {webrtcReceiverStatus === WEBRTC_STATUS.PENDING && (
          <Grid sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }} alignItems='center'>
            <CircularProgress />
          </Grid>
          )}
          {webrtcReceiverStatus === WEBRTC_STATUS.ERROR && (
          <Grid sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }} alignItems='center'>
            <ErrorOutlineIcon color='error' fontSize='large' />
          </Grid>
          )}
        </div>
      </div>
      <div>
        <Dialog open={open} onClose={closePermissionDialog} disableEscapeKeyDown>
          <DialogTitle id='permission-alert'>
            Enable video auto play with sound
          </DialogTitle>
          <DialogContent>
            <DialogContentText id='permission-alert-description'>
              Let Tokkio UI play streams with audio. Without this permission
              video playback will be muted.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={closePermissionDialog}>Disagree</Button>
            <Button onClick={()=> {
              closePermissionDialog();
              enableAudio();
              }}
              autoFocus
              >
              Agree
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </Grid>
  );
}

export default Webrtc;
