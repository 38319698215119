/* eslint-disable no-prototype-builtins */
import { createSlice } from "@reduxjs/toolkit";

const webSocketInitialState = {
  uuid: "",
  status: 0,
  data: [],
  streamId: "",
  sessionActive: false,
};

const webSocketSlice = createSlice({
  name: "uuid",
  initialState: webSocketInitialState,
  reducers: {
    setSessionActive(state, action) {
      state.sessionActive = action.payload;
    },
    setWebsocket(state, action) {
      state.uuid = action.payload.uuid;
      state.status = action.payload.status;
    },
    setItems(state, action) {
      state.data = action.payload.data;
      state.status = action.payload.status;
    },
    setStreamId(state, action) {
      state.streamId = action.payload;
    },
  },
});

export const webSocketActions = webSocketSlice.actions;

export default webSocketSlice;
