/* eslint-disable no-console */
import React from "react";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Typography from '@mui/material/Typography';
import { useLocation } from "react-router-dom";

function WaitingMessage() {
  const sessionActive = useSelector((state) => state.uuid.sessionActive);
  const location = useLocation().pathname;
  const shouldRenderMessage = 
    !sessionActive &&
    location === "/welcome";
  // Please move in view of the camera to start your order
  return (
    <Box sx={{
      display: shouldRenderMessage ? "inline" : "none",
      justifyContent: "center",
      width: "100%",
    }}>
      <Typography 
        align='center'
        color='primary.main'
        fontSize='18px'
        sx={{
          p: 3.7,
        }}>Please move in view of the camera to ask your query</Typography>
    </Box>
  );
}

export default WaitingMessage;