/* eslint-disable no-prototype-builtins */
import { createSlice } from "@reduxjs/toolkit";

const feedbackInitialState = {
  feedback: false
};

/* Redux slice to manage feedback enable / disable flag */
const feedbackSlice = createSlice({
  name: "feedback",
  initialState: feedbackInitialState,
  reducers: {
    enableFeedback(state) {
        state.feedback = true
    },
    disableFeedback(state) {
        state.feedback = false
    }
},
});

export const feedbackSliceActions = feedbackSlice.actions;

export default feedbackSlice;
